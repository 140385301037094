import { IInfographicsData } from "./../types/infographics.d";
import { saveAs } from "file-saver";
import { IPage } from "../types/page";
import { IPagination } from "./../types/pagination.d";
import { baseAPI } from "./base";
import { ITwitchChannel, ITwitchStreamsFilters } from "../types/twitch";

const baseAPIEnhanced = baseAPI.enhanceEndpoints({
  addTagTypes: ["Channel", "Post"],
});

const baseURL = "/twitch";

// const baseURLChannels = baseURL + "/channels";
const baseURLChannels = baseURL + "/streams";
const baseURLPosts = baseURL + "/posts";
const baseURLPhotos = baseURL + "/photos";


export const twitchAPI = baseAPIEnhanced.injectEndpoints({
  endpoints: (builder) => ({
    getTwitchChannels: builder.query<
      IPage<ITwitchChannel>,
      Partial<ITwitchStreamsFilters & IPagination>
    >({
      query: (params) => {
        return {
          url: `${baseURLChannels}`,
          method: "GET",
          params: {
            ...params,
            sort_field: "stream_name",
          },
        };
      },
      providesTags: ["Channel"],
    }),

    getTwitchChannelById: builder.query<ITwitchChannel, string>({
      query: (channel_id) => ({
        url: `${baseURLChannels}/${channel_id}`,
        method: "GET",
      }),
      providesTags: (result, error, channel_id) => [{ type: "Channel", id: channel_id }],
    }),
  }),
});
