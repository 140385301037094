// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip_container__hqeHM {
  position: relative;
  display: flex;
}

.tooltip_tooltip__1lXqa {
  position: absolute;
  /* width: 180px; */
  padding: 4px 12px;
  margin-left: calc(-50%);
  justify-content: center;
  color: #ffffff;
  background-color: #ff8e00;
  border-radius: 12px;
  text-align: center;
  white-space: pre-line;
  font-weight: 700;
  pointer-events: none;
  top: -50px;
  left: 30%;
  width: auto;
  /* min-width: 50px; */
  /* max-width: 180px; */
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/tooltip.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,uBAAuB;EACvB,uBAAuB;EACvB,cAAc;EACd,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,qBAAqB;EACrB,gBAAgB;EAChB,oBAAoB;EACpB,UAAU;EACV,SAAS;EACT,WAAW;EACX,qBAAqB;EACrB,sBAAsB;AACxB","sourcesContent":[".container {\n  position: relative;\n  display: flex;\n}\n\n.tooltip {\n  position: absolute;\n  /* width: 180px; */\n  padding: 4px 12px;\n  margin-left: calc(-50%);\n  justify-content: center;\n  color: #ffffff;\n  background-color: #ff8e00;\n  border-radius: 12px;\n  text-align: center;\n  white-space: pre-line;\n  font-weight: 700;\n  pointer-events: none;\n  top: -50px;\n  left: 30%;\n  width: auto;\n  /* min-width: 50px; */\n  /* max-width: 180px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `tooltip_container__hqeHM`,
	"tooltip": `tooltip_tooltip__1lXqa`
};
export default ___CSS_LOADER_EXPORT___;
