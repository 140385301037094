import React from 'react'
import { IPermissibleSortBy, SortDirection  } from '../../../types/filters'
import classNames from 'classnames'

interface IProps {
  onChangeSortBy: (sort_field: string) => void
  onChangeSortDirection: (sort_direction: SortDirection) => void
  sort_field: string
  sort_direction: SortDirection
  permissibleSortBy: Array<IPermissibleSortBy>
}



const FiltersSortComponent: React.FC<IProps> = ({ sort_field, sort_direction, onChangeSortBy, onChangeSortDirection, permissibleSortBy }) => {

  
  return (
    <div className="filter js-filter-content" data-id="sort">
      <div className="filter__select">
        <div className="select select_small">
          <select value={sort_field} onChange={(e) => onChangeSortBy(e.target.value)} className="js-formstyler">
            {permissibleSortBy.map((sortByItem) => <option key={sortByItem.code} value={sortByItem.code} > {sortByItem.text} </option>)}
          </select>
        </div>
      </div>
      <div className="date-nav date-nav_filter date-nav_hide-mob">
        <ul className="date-nav__list">
          {
            permissibleSortBy.map(sortByItem => (
              <li key={sortByItem.code} className={classNames("date-nav__item", "js-filters-item", {
                active: sort_field === sortByItem.code
              })}>
                <button onClick={() => onChangeSortBy(sortByItem.code)} className="date-nav__button js-filters-button" type="button">{sortByItem.text}</button>
              </li>
            ))
          }
        </ul>
      </div>
      <div className="filter__select">

      </div>
      <div className="radio-filter">
        <div className="radio-filter__button radio">
          <label className="radio__label">
            <input onClick={() => onChangeSortDirection("asc")} className="radio__input" type="radio" name="radio-sort" checked={sort_direction === "asc"} />
            <span className="radio__icon"></span>
            <span className="radio__text">По возрастанию</span>
          </label>
        </div>
        <div className="radio-filter__button radio">
          <label className="radio__label">
            <input onClick={() => onChangeSortDirection("desc")} className="radio__input" type="radio" name="radio-sort" checked={sort_direction === "desc"} />
            <span className="radio__icon"></span>
            <span className="radio__text">По убыванию</span>
          </label>
        </div>
      </div>
    </div>)
}

export const FilterSort = React.memo(FiltersSortComponent)
