import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTypedDispatch} from "../../../hooks/common";
import {Pagination} from "../../../components/Inner/Pagination";
import {setFiltersUploaded, setPaginationPage, toggleSelectedUser} from "../../../store/vk/users/reducer";
import {useSelector} from "react-redux";
import {VkUsersTable} from "../../../components/Inner/Vk/VkTables/VkUsersTable";
import {vkAPI} from "../../../api/vk";
import {vkUsersSelectors} from "../../../store/vk/users/selectors";
import {usersAPI} from "../../../api/users";
import {FileUploader} from "react-drag-drop-files";

export const VkUpload = () => {
    const filters = useSelector(vkUsersSelectors.filters)
    const selectedUsers = useSelector(vkUsersSelectors.selectedUsers)
    const pagination = useSelector(vkUsersSelectors.pagination)

    const initFilters = {...filters};
    const [file, setAddedFile] = useState<File | null>(null);
    const {data: usersResponse, refetch} = vkAPI.useGetVkUsersFromCsvQuery({file, ...pagination})
    const clearCsv = () => {
        setAddedFile(null);
    };
    const handleUpload = async (file: File) => {
        setAddedFile(file);
        refetch()
    }

    const count = usersResponse?.total || 0
    const users = usersResponse?.items || []

    const [deleteUser] = usersAPI.useDeleteUserMutation()
    const [checkUser] = vkAPI.useCheckVkUserMutation()
    const [updateUser] = usersAPI.useUpdateUserMutation()
    const [isAllToggled, setIsAllToggled] = useState<boolean>(false)

    const dispatch = useTypedDispatch()

    const toggleSelectedUserHandler = useCallback((id: number) => {
        dispatch(toggleSelectedUser({id}))
    }, [])

    const setIsAllToggledHandler = () => {
        if (!users) {
            return
        }

        for (let user of users) {
            if (isAllToggled) {
                dispatch(toggleSelectedUser({id: user.id, isSelected: false}))
            } else {
                dispatch(toggleSelectedUser({id: user.id, isSelected: true}))
            }
        }

        setIsAllToggled(!isAllToggled)
    }

    const onDeleteUser = useCallback((id: number) => {
        deleteUser(id)
    }, [])

    const onChangePage = useCallback((page: number) => {
        dispatch(setPaginationPage({page}));
    }, [pagination])

    const onCheckUser = useCallback((id: number) => {
        checkUser(id)
    }, [])

    const uploader = useMemo(() => {
        return <FileUploader
            handleChange={handleUpload}
            name="file"
            types={['CSV']}
            hoverTitle="Импорт"
            title="Импорт"
            fileOrFiles={file}
        />
    }, [handleUpload, file])

    return (
        <>
            <div className="filter js-filter-content" data-id="search">
                <div className="filter js-filter-content" data-id="date"
                     style={{display: "flex", justifyContent: "center"}}>
                    {uploader}
                    <button onClick={clearCsv}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em"
                             preserveAspectRatio="xMidYMid meet"
                             viewBox="0 0 32 32">
                            <path fill="currentColor" d="M26 20h-6v-2h6zm4 8h-6v-2h6zm-2-4h-6v-2h6z"/>
                            <path fill="currentColor"
                                  d="M17.003 20a4.895 4.895 0 0 0-2.404-4.173L22 3l-1.73-1l-7.577 13.126a5.699 5.699 0 0 0-5.243 1.503C3.706 20.24 3.996 28.682 4.01 29.04a1 1 0 0 0 1 .96h14.991a1 1 0 0 0 .6-1.8c-3.54-2.656-3.598-8.146-3.598-8.2Zm-5.073-3.003A3.11 3.11 0 0 1 15.004 20c0 .038.002.208.017.469l-5.9-2.624a3.8 3.8 0 0 1 2.809-.848ZM15.45 28A5.2 5.2 0 0 1 14 25h-2a6.5 6.5 0 0 0 .968 3h-2.223A16.617 16.617 0 0 1 10 24H8a17.342 17.342 0 0 0 .665 4H6c.031-1.836.29-5.892 1.803-8.553l7.533 3.35A13.025 13.025 0 0 0 17.596 28Z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <br/>
            {usersResponse?.total && <div className="table-block">
                <VkUsersTable
                    toggleSelectedUserHandler={toggleSelectedUserHandler}
                    selectedUsers={selectedUsers} setIsAllToggledHandler={setIsAllToggledHandler}
                    users={usersResponse?.items || []}
                    isAllToggled={isAllToggled} onDelete={onDeleteUser} onCheck={onCheckUser}
                />

                <Pagination onChangePage={onChangePage} page={pagination.page} count={count ?? 0}
                            perPage={pagination.perPage}/>
            </div>}
        </>
    )
};

export default VkUpload;