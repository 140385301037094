import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IVkInfographicsGroupsFilters } from '../../../../types/vk';

const baseFilters = {
    min_subscribers: 0,
    max_subscribers: 10000000,
    country: null,
    region: null,
    actual: false,
    city: null
} as IVkInfographicsGroupsFilters

const initialState = {
    filters: cloneDeep(baseFilters),
    currentFilters: cloneDeep(baseFilters)

}

export const vkGroupsInfographicsSlice = createSlice({
    name: "vkGroupsInfographicsSlice",
    reducers: {
        setFiltersCountry(state, action: PayloadAction<{ country: string | null }>) {
            state.filters.country = action.payload.country
        },
        setFiltersDistrict(state, action: PayloadAction<{ region: string | null }>) {
            state.filters.region = action.payload.region
        },
        setFiltersCity(state, action: PayloadAction<{ city: string | null }>) {
            state.filters.city = action.payload.city
        },
        setFiltersSubscribersCountMin(state, action: PayloadAction<{ min_subscribers: number }>) {
            state.filters.min_subscribers = action.payload.min_subscribers
        },
        setFiltersSubscribersCountMax(state, action: PayloadAction<{ max_subscribers: number }>) {
            state.filters.max_subscribers = action.payload.max_subscribers
        },
        setCurrentFilters(state, action: PayloadAction<{ currentFilters: IVkInfographicsGroupsFilters }>) {
            state.currentFilters = { ...action.payload.currentFilters }
        },
        clearFilters(state) {
            state.filters = cloneDeep(baseFilters)
        },
    },
    initialState
})

export const {
    setCurrentFilters, clearFilters, setFiltersCountry, setFiltersDistrict, setFiltersSubscribersCountMax,
    setFiltersSubscribersCountMin, setFiltersCity
} = vkGroupsInfographicsSlice.actions