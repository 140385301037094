export const passwordRecoveryPath = "/password-recovery"
export const forgotPasswordPath = "/forgot-password"
export const technicalSupportPath = "/technical-support"
export const registrationPath = "/registration"
export const loginPath = "/login"

export const profilePath = "/profile"

//vk
export const vkPath = "/vk"
export const vkCommunitiesPath = vkPath + "/groups"
export const vkCommunitiesAvailablePath = vkPath + "/available_groups"
export const vkCommunitiesDeletePath = vkPath + "/deleted"
// export const vkCommunitiesPath = vkPath + "/communities"
export const vkUsersPath = vkPath + "/users"
// export const vkUsersPath = vkPath + `/groups/`
export const vkSearchPath = vkPath + "/search"
export const vkComparePath = vkPath + "/compare"
export const vkInfographicsPath = vkPath + "/infographics"
export const vkUploadPath = vkPath + "/upload"

// telegram
export const telegramPath = "/telegram"
export const telegramChannelsPath = telegramPath + "/channels"
export const telegramDeletedChannelsPath = telegramChannelsPath + "/deleted"
export const telegramPostsPath = telegramPath + "/posts"
export const telegramInfographicsPath = telegramPath + "/infographics"

//tiktok
export const tiktokPath = "/tiktok"
export const tiktokUsersPath = tiktokPath + "/users"
export const tiktokDeleteUsersPath = tiktokPath + "/deleted"
export const tiktokPostsPath = tiktokPath + "/posts"
export const tiktokInfographicsPath = tiktokPath + "/infographics"

// youtube
export const youtubePath = "/youtube"
export const youtubeChannelsPath = youtubePath + "/channels"
export const youtubeChannelsDeletePath = youtubePath + "/deleted"
export const youtubeVideosPath = youtubePath + "/posts"
export const youtubeInfographicsPath = youtubePath + "/infographics"

// twitter
export const twitterPath = "/twitter"
export const twitterUsersPath = twitterPath + "/users"
export const twitterPostsPath = twitterPath + "/posts"
export const twitterInfographicsPath = twitterPath + "/infographics"

// twitch
export const twitchPath = "/twitch"
export const twitchUsersPath = twitchPath + "/users"
// one stream
export const twitchStreamPath = twitchPath + "/stream"
// all streams
export const twitchStreamsPath = twitchPath + "/streams"
export const twitchDeletedChannelsPath = twitchPath + "/deleted"
//ok
export const okPath = "/ok"
export const okGroupsPath = okPath + "/groups"
export const okDeletedGroupsPath = okGroupsPath + "/deleted"
export const okPostsPath = okPath + "/posts"

export const subculturesAndTagsPath = "/subcultures-and-tags"
export const subculturesPath = subculturesAndTagsPath + "/subcultures"
export const tagsPath = subculturesAndTagsPath + "/tags"

export const usersAndSupportPath = "/usersAndSupport"
export const usersPath = usersAndSupportPath + '/users'
export const newUserPath = usersPath + "/new"

export const supportPath = usersAndSupportPath + "/support"


export const parsersPath = "/parsers"
export const parsersVkPath = parsersPath + "/vk"
export const parsersVkGroupsPath = parsersVkPath + "/communities"
export const parsersVkUsersPath = parsersVkPath + "/users"
export const parsersTiktokPath = parsersPath + "/tiktok"
export const parsersTiktokUsersPath = parsersTiktokPath + "/users"
export const parsersTiktokPostsPath = parsersTiktokPath + "/posts"
export const parsersTelegramPath = parsersPath + "/telegram"
export const parsersTelegramChannelsPath = parsersTelegramPath + "/channels"
export const parsersTelegramPostsPath = parsersTelegramPath + "/posts"

export const parsersTwitterUsersPath = parsersTiktokPath + "/users"
export const parsersTwitterPostsPath = parsersTiktokPath + "/posts"


export const statisticsPath = "/statistics"

// training-materils
export const trainingMaterialsPath = '/training-materials'
export const trainingVideosPath = trainingMaterialsPath + '/video'
