import { ITelegramPostsFilters } from './../../../../types/telegram.d';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPagination } from '../../../../types/pagination';

const initialState = {
    pagination: {
        page: 1,
        perPage: 5
    } as IPagination,
    filters: {
        sort_field: "create_time",
        sort_direction: "desc"
    } as ITelegramPostsFilters
}

export const telegramChannelDetailSlice = createSlice({
    name: "telegramChannelDetail",
    initialState,
    reducers: {
        setPaginationPage(state, action: PayloadAction<{ page: number }>) {
            state.pagination.page = action.payload.page
        },
        setPaginationPerPage(state, action: PayloadAction<{ perPage: number }>) {
            state.pagination.perPage = action.payload.perPage
        },
    },

})

export const { setPaginationPage, setPaginationPerPage } = telegramChannelDetailSlice.actions