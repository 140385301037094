import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {cloneDeep} from "lodash";
import {SortBy, ISubculturesAndTagsFilters} from "../../../types/subculturesAndTags";
import {SortDirection} from "../../../types/filters";

const baseFilters = {
    keyword: "",
    sort_direction: "asc",
    sort_field: "subculture_name"
} as ISubculturesAndTagsFilters

export const subculturesSlice = createSlice({
    name: "subcultures",
    initialState: {
        pagination: {
            perPage: 20,
            page: 1
        },
        filters: cloneDeep(baseFilters),
        currentFilters: cloneDeep(baseFilters)
    },
    reducers: {
        setFiltersKeyword(state, action: PayloadAction<{ keyword: string }>) {
            state.filters.keyword = action.payload.keyword
        },
        setPaginationPage(state, action: PayloadAction<{ page: number }>) {
            state.pagination.page = action.payload.page
        },
        setPaginationPerPage(state, action: PayloadAction<{ perPage: number }>) {
            state.pagination.perPage = action.payload.perPage
        },
        setCurrentFilters(state, action: PayloadAction<{ currentFilters: ISubculturesAndTagsFilters }>) {
            state.currentFilters = action.payload.currentFilters
        },
        setFiltersSortBy(state, action: PayloadAction<{ sort_field: SortBy }>) {
            state.filters.sort_field = action.payload.sort_field
        },
        setFiltersSortDirection(state, action: PayloadAction<{ sort_direction: SortDirection }>) {
            state.filters.sort_direction = action.payload.sort_direction
        },
        clearFilters(state) {
            state.filters = cloneDeep(baseFilters)
        },
    }
})

export const {
    clearFilters,
    setFiltersKeyword,
    setPaginationPage,
    setPaginationPerPage,
    setCurrentFilters,
    setFiltersSortBy,
    setFiltersSortDirection
} = subculturesSlice.actions