export const baseURL = process.env.REACT_APP_API

export const pro = process.env.REACT_APP_PRO
export const version = process.env.VERSION

const storage = "https://storage.yandexcloud.net"
export const accountStorage = baseURL + "/account/"

export const telegramStorage = baseURL + "/api/telegram/"

export const vkStorage = storage + "/supfire-vk/"


// export const tiktokStorage = storage + "/tiktok/"
export const tiktokStorage = baseURL + "/api/tiktok/"
export const tiktokBaseUrl = "https://tiktok.com"

//youtube
export const youtubeStorage = baseURL + "/api/youtube/"

// twitter
export const twitterStorage = baseURL + "/api/twitter/"

// ok
export const okStorage = baseURL + "/api/ok"

export enum UserStatusesEnum {
    accepted = "accepted",
    waiting = "waiting",
    declined = "declined"
}

export enum UserRightsEnum {
    user = 1,
    moderator = 2,
    admin = 3
}

export enum RankEnum {
    // no_threat = 0,
    small_threat = 3,
    medium_threat = 2,
    big_threat = 1,
}
