import classNames from 'classnames'
import React, { Suspense } from 'react'
import { Outlet, useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { NavTab } from '../../components/Common/UI/NavTab'
import { twitchDeletedChannelsPath, twitchStreamsPath, twitchStreamPath } from '../../constants/links'
import {useGetCurrentUser} from '../../hooks/account'
import {is_admin, is_moderator} from "../../utils/rights";

export const TwitchView = () => {
    const location = useLocation()
    const { data: user } = useGetCurrentUser()


    return (
        <>
            <div className="inner-page__top">
                <h1 className="inner-page__title inner-page__title_no-padding">Twitch</h1>
            </div>
            <div className="user-nav">
                <div className="user-nav__scroll js-user-scroll">
                    <ul className="user-nav__list">
                        <NavTab currentPath={location.pathname} path={twitchStreamsPath}>Каналы</NavTab>
                        {/* { (is_admin(user) || is_moderator(user))  && <NavTab currentPath={location.pathname} path={twitchDeletedChannelsPath}>Удаленные каналы</NavTab>} */}
                        {/* <NavTab currentPath={location.pathname} path={twitchStreamPath}>Записи</NavTab> */}
                        {/*{<NavTab currentPath={location.pathname} path={telegramInfographicsPath}>Инфографика</NavTab> */}
                    </ul>
                </div>
            </div>
            <Suspense>
                <Outlet />
            </Suspense>
        </>
    )
}
