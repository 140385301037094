import { cloneDeep } from 'lodash';
import { SortDirection } from '../../types/filters';
import { IUsersFilters, SortBy } from '../../types/users';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subtractDate } from '../../utils/date';

const baseFilters = {
    keyword: "",
    district: "",
    status: null,
    cities: "",
    sort_field: "first_name" as SortBy,
    sort_direction: "asc" as SortDirection,
    dateAt: null,
    dateTo: null,
    uploaded: false
} as IUsersFilters

const initialState = {
    filters: cloneDeep(baseFilters),
    currentFilters: cloneDeep(baseFilters),
    pagination: {
        perPage: 10,
        page: 1,
    },
    selectedUsers: [] as Array<number>
}

export const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setPaginationPerPage(state, action: PayloadAction<{ perPage: number }>) {
            state.pagination.perPage = action.payload.perPage
        },
        setPaginationPage(state, action: PayloadAction<{ page: number }>) {
            state.pagination.page = action.payload.page
        },
        setFiltersKeyword(state, action: PayloadAction<{ keyword: string }>) {
            state.filters.keyword = action.payload.keyword
        },
        setFiltersSortBy(state, action: PayloadAction<{ sort_field: SortBy }>) {
            state.filters.sort_field = action.payload.sort_field
        },
        setFiltersDistrict(state, action: PayloadAction<{ district: string }>) {
            state.filters.district = action.payload.district
        },
        setFiltersDateAt(state, action: PayloadAction<{ dateAt: Date | null }>) {
            state.filters.dateAt = action.payload.dateAt?.getTime() ?? null
        },
        setFiltersDateTo(state, action: PayloadAction<{ dateTo: Date | null }>) {
            state.filters.dateTo = action.payload.dateTo?.getTime() ?? null
        },

        setFiltersUploaded(state, action: PayloadAction<{ uploaded: boolean }>) {
            state.filters.uploaded = action.payload.uploaded
        },

        setFiltersCity(state, action: PayloadAction<{ cities: string }>) {
            state.filters.cities = action.payload.cities
        },
        setFiltersStatus(state, action: PayloadAction<{ status: string | null }>) {
            state.filters.status = action.payload.status
        },
        setCurrentFilters(state, action: PayloadAction<{currentFilters: IUsersFilters}>) {
            state.currentFilters = action.payload.currentFilters
        },
        setFiltersSortDirection(state, action: PayloadAction<{ sort_direction: SortDirection }>) {
            state.filters.sort_direction = action.payload.sort_direction
        },
        clearFilters(state) {
            state.filters = cloneDeep(baseFilters)
        },
        toggleSelectedUser(state, action: PayloadAction<{ id: number, isSelected?: boolean }>) {
            const id = action.payload.id
            const isSelected = action.payload.isSelected

            if (isSelected !== true && state.selectedUsers.includes(id)) {
                state.selectedUsers.splice(state.selectedUsers.indexOf(id), 1)
            } else if (!state.selectedUsers.includes(id)) {
                state.selectedUsers.push(id)
            }
        },
        clearSelectedUsers(state) {
            state.selectedUsers = []
        }
    }
})

export const {
    setFiltersStatus, setFiltersKeyword, setPaginationPage,
    setPaginationPerPage, setFiltersDateAt, setFiltersDateTo, setFiltersUploaded, setCurrentFilters,
    setFiltersDistrict, setFiltersCity, clearFilters,
    setFiltersSortBy, setFiltersSortDirection, toggleSelectedUser, clearSelectedUsers } = usersSlice.actions