import { Suspense } from 'react'
import { lazily } from 'react-lazily'
import { Navigate, Route, Routes, useLocation } from 'react-router'
import { Footer } from '../../components/Common/Footer'
import { Header } from '../../components/Inner/Header'
import {
  vkUploadPath,
  newUserPath,
  parsersPath,
  parsersTelegramChannelsPath,
  parsersTelegramPath,
  parsersTelegramPostsPath,
  parsersTiktokPath,
  parsersTiktokPostsPath,
  parsersTiktokUsersPath,
  parsersVkGroupsPath,
  parsersVkPath,
  parsersVkUsersPath,
  profilePath,
  statisticsPath,
  subculturesAndTagsPath,
  subculturesPath,
  tagsPath,
  technicalSupportPath,
  telegramChannelsPath,
  telegramInfographicsPath,
  telegramPath,
  telegramPostsPath,
  tiktokInfographicsPath,
  tiktokPath,
  tiktokPostsPath,
  tiktokUsersPath,
  usersAndSupportPath,
  usersPath,
  vkCommunitiesPath,
  vkComparePath,
  vkInfographicsPath,
  vkPath,
  vkSearchPath,
  vkUsersPath,
  youtubePath,
  youtubeChannelsPath,
  youtubeVideosPath,
  youtubeInfographicsPath,
  telegramDeletedChannelsPath,
  twitterPath,
  twitterUsersPath,
  twitterPostsPath,
  tiktokDeleteUsersPath,
  vkCommunitiesDeletePath,
  youtubeChannelsDeletePath,
  vkCommunitiesAvailablePath,
  trainingMaterialsPath,
  trainingVideosPath,
  twitchPath,
  okPath,
  okGroupsPath,
  okDeletedGroupsPath,
  supportPath,
  twitchStreamPath,
  twitchStreamsPath,
} from '../../constants/links'
import {useGetCurrentUser} from '../../hooks/account'
import VkUpload from "../../pages/Inner/Vk/VkUpload";
import {is_admin, is_moderator} from "../../utils/rights";
import { TwitchView } from './TwitchView'

const { Profile } = lazily(() => import('../../pages/Inner/Profile'))
//telegram
const { TelegramView } = lazily(() => import('./TelegramView'))
const { TelegramChannels } = lazily(() => import('../../pages/Inner/Telegram/TelegramChannels'))
const { TelegramChannelsDelete } = lazily(() => import('../../pages/Inner/Telegram/TelegramChannelsDelete'))
const { TelegramDetailChannel } = lazily(() => import('../../pages/Inner/Telegram/TelegramDetailChannel'))
const { TelegramPosts } = lazily(() => import('../../pages/Inner/Telegram/TelegramPosts'))
const { TelegramInfographics } = lazily(() => import('./../../pages/Inner/Telegram/TelegramInfographics'))
//tiktok
const { TiktokView } = lazily(() => import('./TIktokView'))
const { TiktokPosts } = lazily(() => import('../../pages/Inner/Tiktok/TiktokPosts'))
const { TiktokUsers } = lazily(() => import('../../pages/Inner/Tiktok/TiktokUsers'))
const { TiktokUsersDeleted } = lazily(() => import('../../pages/Inner/Tiktok/TiktokUsersDelete'))
const { TiktokDetailUser } = lazily(() => import('../../pages/Inner/Tiktok/TiktokDetailUser'))
const { TiktokInfographics } = lazily(() => import('../../pages/Inner/Tiktok/TiktokInfographics'))
//vk
const { VkView } = lazily(() => import('./VkView'))
const { VkGroups } = lazily(() => import('../../pages/Inner/Vk/VkGroups'))
const { VkDetailGroup } = lazily(() => import('../../pages/Inner/Vk/VkDetailGroup'))
const { VkDetailUser } = lazily(() => import('../../pages/Inner/Vk/VkDetailUser'))
const { VkUsers } = lazily(() => import('../../pages/Inner/Vk/VkUsers'))
const { VkCompare } = lazily(() => import('./../../pages/Inner/Vk/VkCompare'))
const { VkSearch } = lazily(() => import('./../../pages/Inner/Vk/VkSearch'))
// const { VkInfographics } = lazily(() => import('./../../pages/Inner/Vk/VkInfographics'))

//youtube
const { YouTubeView } = lazily(() => import('./YouTubeView'))
const { YouTubeChannels } = lazily(() => import('../../pages/Inner/YouTube/YouTubeChannels'))
const { YouTubeChannelsDelete } = lazily(() => import('../../pages/Inner/YouTube/YouTubeChannelsDelete'))
const { YouTubeVideos } = lazily(() => import('../../pages/Inner/YouTube/YouTubeVideos'))
const { YouTubeDetailGroup } = lazily(() => import('../../pages/Inner/YouTube/YouTubeDetailGroup'))

//twitter
const { TwitterView } = lazily(() => import('./TwitterView'))
const { TwitterPosts } = lazily(() => import('../../pages/Inner/Twitter/TwitterPosts'))
const { TwitterUsers } = lazily(() => import('../../pages/Inner/Twitter/TwitterUsers'))
const { TwitterDetailUser } = lazily(() => import('../../pages/Inner/Twitter/TwitterDetailUser'))

//ok
const { OkView } = lazily(() => import('./OkView'))
const { OkGroups } = lazily(() => import('../../pages/Inner/Ok/OkGroups'))
const { OkGroupsDelete } = lazily(() => import('../../pages/Inner/Ok/OkGroupsDelete'))
const { OkDetailGroup } = lazily(() => import('../../pages/Inner/Ok/OkDetailGroup'))

// training-materials
const { TrainingMaterialsView } = lazily(() => import('./TrainingMaterialsView'))
const { TrainingVideos } = lazily(() => import('../../pages/Inner/TrainingMaterials/TrainingVideos'))

// twitch
const { TwitchChannels } = lazily(() => import('../../pages/Inner/Twitch/TwitchChannels'))
const { TwitchDetailStreams } = lazily(() => import('../../pages/Inner/Twitch/TwitchDetailStreams'))


// TwitchDetailStreams

const { Users } = lazily(() => import('../../pages/Inner/Users/Users'))
const { Support } = lazily(() => import('../../pages/Inner/Users/Support'))
const { TechnicalSupport } = lazily(() => import('./../../pages/Public/TechnicalSupport/TechnicalSupport'))
const { Statistics } = lazily(() => import('./../../pages/Inner/Statistics'))
const { Subcultures } = lazily(() => import('./../../pages/Inner/SubculturesAndTags/Subcultures'))
const { Tags } = lazily(() => import('./../../pages/Inner/SubculturesAndTags/Tags'))
const { SubculturesAndTagsView } = lazily(() => import('./../../views/Inner/SubculturesAndTagsView'))
const { UsersAndSupportView } = lazily(() => import('./UsersAndSupportView'))
const { ParsersView } = lazily(() => import('./../../views/Inner/Parsers/ParsersView'))
const { ParsersVkView } = lazily(() => import('./../../views/Inner/Parsers/ParsersVkView'))
const { ParsersVkGroups } = lazily(() => import('./../../pages/Inner/Parsers/ParsersVkGroups'))
const { ParsersVkUsers } = lazily(() => import('./../../pages/Inner/Parsers/ParsersVkUsers'))
const { ParsersTelegramView } = lazily(() => import('./../../views/Inner/Parsers/ParsersTelegramView'))
const { ParsersTelegramChannels } = lazily(() => import('./../../pages/Inner/Parsers/ParsersTelegramChannels'))
const { ParsersTiktokUsers } = lazily(() => import('./../../pages/Inner/Parsers/ParsersTiktokUsers'))
const { ParsersTiktokPosts } = lazily(() => import('./../../pages/Inner/Parsers/ParsersTiktokPosts'))
const { ParsersTiktokView } = lazily(() => import('./../../views/Inner/Parsers/ParsersTiktokView'))
const { ParsersTelegramPosts } = lazily(() => import('../../pages/Inner/Parsers/ParsersTelegramPosts'))
const { CurrentUser } = lazily(() => import('../../pages/Inner/Users/CurrentUser'))
const { NewUser } = lazily(() => import('../../pages/Inner/Users/NewUser'))


export const InnerView = () => {
  const { data: user } = useGetCurrentUser()

  return (
    <>
      <div className="page-container page-container_inner">
        <Header />
        <main className="main">
          <section className="inner-page inner-wrapper">
            <Suspense>

              <Routes>
                <Route path={technicalSupportPath} element={<TechnicalSupport />} />
                <Route element={<Profile />} path={profilePath} />
                <Route element={<TelegramView />} path={telegramPath}>
                  <Route element={<TelegramChannels />} path={telegramChannelsPath} />
                  <Route element={<TelegramChannelsDelete />} path={telegramDeletedChannelsPath} />
                  <Route element={<TelegramPosts />} path={telegramPostsPath} />
                  <Route element={<TelegramInfographics />} path={telegramInfographicsPath} />
                  <Route element={<TelegramDetailChannel />} path={telegramChannelsPath + "/:id"} />
                  <Route element={<Navigate to={telegramChannelsPath} />} path="" />
                  <Route element={<Navigate to={telegramChannelsPath} />} path="*" />
                </Route>
                  <Route element={<VkView />} path={vkPath}>
                  <Route element={<VkGroups />} path={vkCommunitiesPath} />
                    <Route element={<VkGroups getAvailable={true}/>} path={vkCommunitiesAvailablePath} />
                  <Route element={<VkGroups getDeleted={true} />} path={vkCommunitiesDeletePath} />
                  <Route element={<VkUsers />} path={vkUsersPath} />
                   <Route element={<VkCompare />} path={vkComparePath} />
                  <Route element={<VkSearch />} path={vkSearchPath} />
                  {/* <Route element={<VkInfographics />} path={vkInfographicsPath} /> */}
                  <Route element={<VkUpload/>} path={vkUploadPath}/>

                  <Route element={<VkDetailUser />} path={vkUsersPath + "/:id"} />
                  <Route element={<VkDetailGroup />} path={vkCommunitiesPath + "/:id"} />

                  <Route element={<Navigate to={vkCommunitiesPath} />} path="" />
                  <Route element={<Navigate to={vkCommunitiesPath} />} path="*" />
                </Route>
                <Route element={<TiktokView />} path={tiktokPath}>
                  <Route element={<TiktokUsers />} path={tiktokUsersPath} />
                  <Route element={<TiktokUsersDeleted />} path={tiktokDeleteUsersPath} />
                  <Route element={<TiktokPosts />} path={tiktokPostsPath} />
                  <Route element={<TiktokInfographics />} path={tiktokInfographicsPath} />

                  <Route element={<TiktokDetailUser />} path={tiktokUsersPath + "/:user_id"} />

                  <Route element={<Navigate to={tiktokUsersPath} />} path="" />
                  <Route element={<Navigate to={tiktokUsersPath} />} path="*" />
                </Route>
                              {/* youtube */}
                <Route element={<YouTubeView/>} path={youtubePath}>
                  <Route element={< YouTubeChannels />} path={youtubeChannelsPath}/>
                  <Route element={< YouTubeChannelsDelete />} path={youtubeChannelsDeletePath}/>
                  <Route element={< YouTubeVideos />} path={youtubeVideosPath}/>
                  <Route element={<Navigate to={youtubeChannelsPath} />} path="" />
                  <Route element={<Navigate to={youtubeChannelsPath} />} path="*" />

                  <Route element={<YouTubeDetailGroup />} path={youtubeChannelsPath + "/:channel_id"} />
                </Route>
                                {/* twitter */}
                <Route element={<TwitterView/>} path={twitterPath}>
                  <Route element={< TwitterPosts />} path={twitterPostsPath}/>
                  <Route element={< TwitterUsers />} path={twitterUsersPath}/>
                  <Route element={<Navigate to={twitterUsersPath} />} path="" />
                  <Route element={<Navigate to={twitterUsersPath} />} path="*" />

                  <Route element={<TwitterDetailUser />} path={twitterUsersPath + "/:user_id"} />
                </Route>
                                {/* ok */}
                <Route element={<OkView />} path={okPath}>
                  <Route element={<OkGroups />} path={okGroupsPath} />
                  <Route element={<OkGroupsDelete />} path={okDeletedGroupsPath} />
                  {/* <Route element={<OkPosts />} path={okPostsPath} /> */}
                  {/* <Route element={<OkInfographics />} path={okInfographicsPath} /> */}
                  <Route element={<OkDetailGroup />} path={okGroupsPath + "/:id"} />
                  <Route element={<Navigate to={okGroupsPath} />} path="" />
                  <Route element={<Navigate to={okGroupsPath} />} path="*" />
                </Route>
                                {/* training-materials */}
                <Route element={<TrainingMaterialsView/>} path={trainingMaterialsPath}>
                  <Route element={< TrainingVideos />} path={trainingVideosPath}/>
                  <Route element={<Navigate to={trainingVideosPath} />} path="" />
                  <Route element={<Navigate to={trainingVideosPath} />} path="*" />
                </Route>

                                                {/* twitch */}
                <Route element={<TwitchView/>} path={twitchPath}>
                  <Route element={< TwitchChannels />} path={twitchStreamsPath}/>
                  <Route element={<TwitchDetailStreams />} path={twitchStreamPath + "/:id"} />
                  {/* TwitchDetailStreams */}
                </Route>
                <Route element={<Statistics />} path={statisticsPath} />

                   <Route element={<SubculturesAndTagsView />} path={subculturesAndTagsPath}>
                     <Route element={<Subcultures />} path={subculturesPath} />
                   <Route element={<Tags />} path={tagsPath} />

                    <Route element={<Navigate to={subculturesPath} />} path="" />
                    <Route element={<Navigate to={subculturesPath} />} path="*" />
                  </Route>


                {is_admin(user) && <Route element={<UsersAndSupportView />} path={usersAndSupportPath}>
                    <Route element={<CurrentUser />} path=":id"></Route>
                    <Route element={<Users />} path={usersPath}></Route>
                    <Route element={<Support/>} path={supportPath}></Route>
                    
                    <Route element={<NewUser />} path={newUserPath}></Route>

                    <Route element={<Navigate to={usersPath} />} path="" />
                    <Route element={<Navigate to={usersPath} />} path="*" />
                  </Route>}
                
                { is_admin(user) && <Route element={<ParsersView />} path={parsersPath}>
                    <Route element={<ParsersVkView />} path={parsersVkPath}>
                      <Route element={<ParsersVkGroups />} path={parsersVkGroupsPath} />
                      <Route element={<ParsersVkUsers />} path={parsersVkUsersPath} />

                      <Route element={<Navigate to={parsersVkGroupsPath} />} path="" />
                      <Route element={<Navigate to={parsersVkGroupsPath} />} path="*" />
                    </Route>
                    <Route element={<ParsersTelegramView />} path={parsersTelegramPath}>
                      <Route element={<ParsersTelegramChannels />} path={parsersTelegramChannelsPath} />
                      <Route element={<ParsersTelegramPosts />} path={parsersTelegramPostsPath} />

                      <Route element={<Navigate to={parsersTelegramChannelsPath} />} path="" />
                      <Route element={<Navigate to={parsersTelegramChannelsPath} />} path="*" />
                    </Route>
                    <Route element={<ParsersTiktokView />} path={parsersTiktokPath}>
                      <Route element={<ParsersTiktokUsers />} path={parsersTiktokUsersPath} />
                      <Route element={<ParsersTiktokPosts />} path={parsersTiktokPostsPath} />

                      <Route element={<Navigate to={parsersTiktokUsersPath} />} path="" />
                      <Route element={<Navigate to={parsersTiktokUsersPath} />} path="*" />
                    </Route>

                    <Route element={<Navigate to={parsersVkPath} />} path="" />
                    <Route element={<Navigate to={parsersVkPath} />} path="*" />
                  </Route>
                }
                <Route element={<Navigate to={profilePath} />} path="*" />
              </Routes>
            </Suspense>

          </section>
        </main>
        <Footer />
      </div>
    </>
  )
}
