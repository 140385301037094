import {IDistrict, IUser, IUserRole, IUserStatus} from './../types/users.d';
import { baseAPI } from './base';

const baseURL = "account"

export const accountAPI = baseAPI.injectEndpoints({
    endpoints: (builder) => ({
        getCurrent: builder.query<IUser | null, { access_token: string }>({
            query: (body) => {
                return {
                    url: `${baseURL}/info`,
                    method: "POST",
                    body
                }
            },
        }),
        uploadImage: builder.mutation<void, {
            email: string,
            file: File
        }>({
            query: (body) => {
                const formData = new FormData()
                formData.append("email", body.email)
                formData.append("imageFile", body.file)
                return {
                    url: `${baseURL}/upload-image`,
                    method: "POST",
                    body: formData
                }
            }
        }),
        getStatuses: builder.query<Array<IUserStatus>, void>({
            query: () => `${baseURL}/statuses`
        }),
        getRoles: builder.query<Array<IUserRole>, void>({
            query: () => `${baseURL}/rights`
        }),
        getDistricts: builder.query<Array<IDistrict>, void>({
            query: () => `${baseURL}/districts`
        })
    }),
})