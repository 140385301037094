import { cloneDeep } from 'lodash';
import { subtractDate } from './../../../utils/date';
import { SortDirection } from './../../../types/filters.d';
import { ITelegramPostsFilters, SortBy } from './../../../types/telegram.d';
import { IPagination } from '../../../types/pagination';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITwitchPostsFilters } from '../../../types/twitch';


const baseFilters = {
    tags: null as string | null,
    subcultures: null as string | null,
    keyword: "",
    sort_field: "create_time" as SortBy,
    sort_direction: "desc" as SortDirection,
    dateAt: null,
    dateTo: null,
    isChecked: false,
    min_reactions: 0,
    max_reactions: 10000000,
    min_comments: 0,
    max_comments: 10000000
} as ITwitchPostsFilters

const initialState = {
    filters: cloneDeep(baseFilters),
    currentFilters: cloneDeep(baseFilters),
    pagination: {
        page: 1,
        perPage: 20
    } as IPagination,
    selectedPosts: [] as Array<number>
}

export const twitchPostsSlice = createSlice({
    name: "twitchPosts",
    initialState,
    reducers: {
        setFiltersTag(state, action: PayloadAction<{ tags: string | null }>) {
            state.filters.tags = action.payload.tags
        },
        setFiltersSubcultures(state, action: PayloadAction<{ subcultures: string | null }>) {
            state.filters.subcultures = action.payload.subcultures
        },
        setFiltersKeyword(state, action: PayloadAction<{ keyword: string }>) {
            state.filters.keyword = action.payload.keyword
        },
        setFiltersSortBy(state, action: PayloadAction<{ sort_field: SortBy }>) {
            state.filters.sort_field = action.payload.sort_field
        },
        setFiltersSortDirection(state, action: PayloadAction<{ sort_direction: SortDirection }>) {
            state.filters.sort_direction = action.payload.sort_direction
        },
        setFiltersDateAt(state, action: PayloadAction<{ dateAt: Date | null }>) {
            state.filters.dateAt = action.payload.dateAt?.getTime() ?? null
        },
        setFiltersIsChecked(state, action: PayloadAction<{ isChecked: boolean }>) {
            state.currentFilters.isChecked = action.payload.isChecked
            state.filters.isChecked = action.payload.isChecked
        },
        setFiltersCommentsCountMin(state, action: PayloadAction<{ min_comments: number }>) {
            state.filters.min_comments = action.payload.min_comments
        },
        setFiltersCommentsCountMax(state, action: PayloadAction<{ max_comments: number }>) {
            state.filters.max_comments = action.payload.max_comments
        },
        setFiltersReactionsCountMin(state, action: PayloadAction<{ min_reactions: number }>) {
            state.filters.min_reactions = action.payload.min_reactions
        },
        setFiltersReactionsCountMax(state, action: PayloadAction<{ max_reactions: number }>) {
            state.filters.max_reactions = action.payload.max_reactions
        },
        setFiltersDateTo(state, action: PayloadAction<{ dateTo: Date | null }>) {
            state.filters.dateTo = action.payload.dateTo?.getTime() ?? null
        },
        clearFilters(state) {
            state.filters = cloneDeep(baseFilters)
        },
        setCurrentFilters(state, action: PayloadAction<{ currentFilters: ITelegramPostsFilters }>) {
            state.currentFilters = action.payload.currentFilters
        },
        setPaginationPage(state, action: PayloadAction<{ page: number }>) {
            state.pagination.page = action.payload.page
        },
        setPaginationPerPage(state, action: PayloadAction<{ perPage: number }>) {
            state.pagination.perPage = action.payload.perPage
        },
        setRankFilter(state, action: PayloadAction<{ ranks: string | null }>) {
            state.filters.ranks = action.payload.ranks
        },
        toggleSelectedPost(state, action: PayloadAction<{ id: number, isSelected?: boolean }>) {
            const id = action.payload.id
            const isSelected = action.payload.isSelected

            if (isSelected !== true && state.selectedPosts.includes(id)) {
                state.selectedPosts.splice(state.selectedPosts.indexOf(id), 1)
            } else if (!state.selectedPosts.includes(id)) {
                state.selectedPosts.push(id)
            }
        },
        clearSelectedPosts(state) {
            state.selectedPosts = []
        }
    }
})

export const {
    setFiltersTag, setCurrentFilters, setFiltersSubcultures, setPaginationPage, setPaginationPerPage, clearFilters,
    setFiltersKeyword, setFiltersSortBy, setFiltersSortDirection, setFiltersDateAt, setFiltersDateTo, toggleSelectedPost,
    setFiltersCommentsCountMax, setFiltersCommentsCountMin, setFiltersReactionsCountMax, setFiltersReactionsCountMin,
    clearSelectedPosts, setFiltersIsChecked, setRankFilter } = twitchPostsSlice.actions 
