import { IVkInfographicsPostsFilters } from './../../../../types/vk.d';
import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const baseFilters = {
    country: null,
    region: null,
    actual: false,
    city: null
} as IVkInfographicsPostsFilters

const initialState = {
    filters: cloneDeep(baseFilters),
    currentFilters: cloneDeep(baseFilters)
}

export const vkPostsInfographicsSlice = createSlice({
    name: "vkPostsInfographicsSlice",
    reducers: {
        setFiltersCountry(state, action: PayloadAction<{ country: string | null }>) {
            state.filters.country = action.payload.country
        },
        setFiltersDistrict(state, action: PayloadAction<{ region: string | null }>) {
            state.filters.region = action.payload.region
        },
        setFiltersCity(state, action: PayloadAction<{ city: string | null }>) {
            state.filters.city = action.payload.city
        },
        setCurrentFilters(state, action: PayloadAction<{ currentFilters: IVkInfographicsPostsFilters }>) {
            state.currentFilters = { ...action.payload.currentFilters }
        },
        clearFilters(state) {
            state.filters = cloneDeep(baseFilters)
        },
    },
    initialState
})

export const {
    setFiltersCity, setCurrentFilters, setFiltersCountry, setFiltersDistrict, clearFilters
} = vkPostsInfographicsSlice.actions