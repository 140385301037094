import { saveAs } from "file-saver";
import { IPage } from "../types/page";
import { IPagination } from "../types/pagination";
import {
    IYouTubeChannel,
    IYouTubeChannelsFilters,
    IYoutubeCountry,
    IYouTubeVideo,
    IYouTubeVideoFilters
} from "../types/youtube"
import { baseAPI } from "./base";

const baseAPIEnhanced = baseAPI.enhanceEndpoints({ addTagTypes: ["Channel", "Posts"]})
const baseURL = "/youtube"
const baseURLChannels = baseURL + "/channels"
const baseURLVideos = baseURL + "/videos"

export const youtubeAPI = baseAPIEnhanced.injectEndpoints({
    endpoints: (builder) => ({
        getYouTubeChannels: builder.query<IPage<IYouTubeChannel>, Partial<IYouTubeChannelsFilters & IPagination>>({ // тут запрос на все каналы
            query: (params) => ({
                url: `${baseURLChannels}`,
                method: "GET",
                params: params
            }),
            providesTags: ["Channel"]
        }),
        getYouTubeChannelsDelete: builder.query<IPage<IYouTubeChannel>, Partial<IYouTubeChannelsFilters & IPagination>>({ // тут запрос на все каналы
            query: (params) => ({
                url: `${baseURLChannels}/deleted`,
                method: "GET",
                params: params
            }),
            providesTags: ["Channel"]
        }),
        getYouTubeChannelById: builder.query<IYouTubeChannel | null, string>({ // получение данных об одной группе\сообщетво
            query: (video_id) => ({
                url: `${baseURLChannels}/${video_id}`,
                method: "GET",
            }),
            providesTags: ["Channel"]
        }),
        deleteYouTubeChannel: builder.mutation<void, number>({
            query: (id) => ({
                url: `${baseURLChannels}/bulk_delete`,
                method: "DELETE",
                body: [id]
            }),
            invalidatesTags: ["Channel"]
        }),
        deleteYouTubeChannels: builder.mutation<void, Array<number>>({
            query: (ids) => ({
                url: `${baseURLChannels}/bulk_delete`,
                method: "DELETE",
                body: ids
            }),
            invalidatesTags: ["Channel"]
        }),
        deleteYouTubeVideo: builder.mutation<void, string>({
            query: (ids) => ({
                url: `${baseURLVideos}/delete`,
                method: "DELETE",
                body: ids
            })
        }),
        deleteYouTubeVideos: builder.mutation<void, Array<number>>({
            query: (ids) => ({
                url: `${baseURLVideos}/delete/bulk`,
                method: "DELETE",
                body: ids
            })
        }),
        getCountries: builder.query<Array<IYoutubeCountry> | undefined, void>({
            query: () => ({
                url: `${baseURL}/countries`,
            }),
        }),
        getYouTubeVideos: builder.query<IPage<IYouTubeVideo>, Partial<IYouTubeVideoFilters & IPagination>>({
            query: (params) => ({
                url: `${baseURLVideos}`,
                method: "GET",
                params: params
            }),
        }),
        getYouTubeVideosCsv: builder.query<File, IYouTubeVideoFilters>({
            query: (params) => ({
                url: `${baseURLVideos}/export`,
                method: "GET",
                params: params,
                responseHandler: async (response) => {
                    const file = new File([await response.blob()], "youtube-videos.csv")
                    saveAs(window.URL.createObjectURL(file), "youtube-videos.csv")
                }
            }),
        }),
        getYouTubeChannelsCsv: builder.query<File, IYouTubeChannelsFilters>({
            query: (params) => ({
                url: `${baseURLChannels}/export`,
                method: "GET",
                params: params,
                responseHandler: async (response) => {
                    const file = new File([await response.blob()], "youtube-channels.csv")
                    saveAs(window.URL.createObjectURL(file), "youtube-channels.csv")
                }
            }),
        }),
        checkYouTubeChannel: builder.mutation<void, number>({
            query: (id) => ({
                url: `${baseURLChannels}/check/${id}`,
                method: "PUT"
            }),
            invalidatesTags: ["Channel"]
        }),

        restoreYouTubeChannel: builder.mutation<void, number>({
            query: (id) => ({
                url: `${baseURLChannels}/bulk_restore`,
                method: "POST",
                body: [id]
            }),
            invalidatesTags: ["Channel"]
        }),
        restoreYouTubeChannels: builder.mutation<void, Array<number>>({
            query: (ids) => ({
                url: `${baseURLChannels}/bulk_restore`,
                method: "POST",
                body: ids
            }),
            invalidatesTags: ["Channel"]
        }),
        // Бронирование Тикток пользователей для анализа
        bookChannel: builder.mutation<void, number>({
            query: (id) => ({
              url: `${baseURLChannels}/${id}/book`,
              method: "POST",
            }),
            invalidatesTags: ["Channel"],
          }),
          unBookChannel: builder.mutation<void, number>({
              query: (id) => ({
                url: `${baseURLChannels}/${id}/unbook`,
                method: "POST",
              }),
              invalidatesTags: ["Channel"],
            }),
          requestBlockChannel: builder.mutation<void, number>({
            query: (id) => ({
              url: `${baseURLChannels}/${id}/block_request`,
              method: "POST",
            }),
            invalidatesTags: ["Channel"],
          }),
          requestUnblockChannel: builder.mutation<void, number>({
            query: (id) => ({
              url: `${baseURLChannels}/${id}/unblock_request`,
              method: "POST",
            }),
            invalidatesTags: ["Channel"],
          }),        
    })
})