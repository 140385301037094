// export const selectPipes = {
//     options: (options: Array<{
//         [key in string]: any
//     }>, 
//     labelKey: string, valueKey: string) => options.map(option => ({
//         label: option[labelKey],
//         value: String(option[valueKey])
//     }))
// }


// export const selectPipes = {
//     options: <T>(
//       options: Array<any>,
//       labelKey: T,
//       valueKey: T
//     ): Array<{ label: T; value: T }> =>
//       options.map(option => ({
//         label: option[labelKey],
//         value: option[valueKey],
//       })),
//   };

export const selectPipes = {
    options: <TLabel, TValue>(
      options: Array<any>,
      labelKey: TLabel,
      valueKey: TValue
    ): Array<{ label: TLabel; value: TValue }> =>
      options.map(option => ({
        label: option[labelKey],
        value: option[valueKey],
      })),
  };
  