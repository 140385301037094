import { pro } from '../constants/api';
import { ITechnicalSupportMessage } from './../types/technicalSupport.d';
import { baseAPI } from './base';
import { ITopics } from '../types/users';

// const baseURL = "/technical-support"

// export const technicalSupportAPI = baseAPI.injectEndpoints({
//     endpoints: (builder) => ({
//         sendMessage: builder.mutation<void, ITechnicalSupportMessage>({
//             query: (body) => ({
//                 url: `${baseURL}`,
//                 body,
//                 method: "POST"
//             })
//         })
//     })
// })


const baseURL = "/support/send_ticket";

export const technicalSupportAPI = baseAPI.injectEndpoints({
    endpoints: (builder) => ({
        sendMessage: builder.mutation<void, ITechnicalSupportMessage & { file: File | null }>({
            query: ({ file, ...formData }) => {
                const form = new FormData();

      
                Object.entries(formData).forEach(([key, value]) => {
                    form.append(key, value as string);
                });

               
                if (file) {
                    console.log("Файл добавляется в запрос:", file.name, file.size, file);
                    form.append("file", file); // Добавляем файл
                }

               
                form.append("pro", pro || "0");

                return {
                    url: `${baseURL}`,
                    body: form,
                    method: "POST",
                    headers: {
                        "Content-Type": undefined,
                    },
                };
            }
        }),
        getTopics: builder.query<Array<ITopics>, void>({
            query: () => 'support/ticket_topics'
        }),
    })
});

