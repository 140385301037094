import { IPage } from './../types/page.d';
import { saveAs } from 'file-saver';
import { IUpdateTagBody, ITagFullInfo } from './../types/tags.d';
import { IUpdateSubcultureBody, ISubcultureFullInfo } from './../types/subcultures.d';
import { ISubculture } from '../types/subcultures';
import { IPagination } from '../types/pagination';
import { ISubculturesAndTagsFilters } from '../types/subculturesAndTags';
import { baseAPI } from './base';
import { ITag } from '../types/tags';

const baseAPIEnhanced = baseAPI.enhanceEndpoints({ addTagTypes: ["Subculture", "Tag"] })

const baseURLSubcultures = "/subcultures"
const baseURLTags = "/tags"

export const subculturesAndTagsAPI = baseAPIEnhanced.injectEndpoints({
    endpoints: (builder) => ({
        getAllSubcultures: builder.query<Array<ISubculture>, void>({
            query: () => ({
                url: `${baseURLSubcultures}/all`,
                method: "GET"
            }),
            providesTags: ["Subculture"]
        }),
        getTagsBySubculture: builder.query<Array<ITag>, string>({
            query: (subcultures: string) => ({
                url: `${baseURLTags}/all?subcultures=${subcultures}`,
                method: "GET"
            }),
            providesTags: ["Tag"]
        }),
        getSubcultures: builder.query<IPage<ISubcultureFullInfo>, ISubculturesAndTagsFilters & IPagination>({
            query: (params) => ({
                url: `${baseURLSubcultures}`,
                params
            }),
            providesTags: ["Subculture"]
        }),
        createSubculture: builder.mutation<ISubculture, Partial<ISubculture>>({
            query: (body) => ({
                url: `${baseURLSubcultures}`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Subculture", "Tag"]
        }),
        deleteSubculture: builder.mutation<void, string>({
            query: (subculture_name) => ({
                url: `${baseURLSubcultures}/${subculture_name}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Subculture", "Tag"]
        }),
        updateSubculture: builder.mutation<void, IUpdateSubcultureBody>({
            query: (dto) => ({
                url: `${baseURLSubcultures}/${dto.subculture_name}`,
                body: dto,
                method: "PUT"
            }),
            invalidatesTags: ["Subculture"]
        }),
        getTags: builder.query<IPage<ITagFullInfo>, ISubculturesAndTagsFilters & IPagination>({
            query: (params) => ({
                url: `${baseURLTags}`,
                params
            }),
            providesTags: ["Tag"]
        }),
        createTag: builder.mutation<ITag, Partial<ITag>>({
            query: (body) => ({
                url: `${baseURLTags}`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Tag"]
        }),
        deleteTag: builder.mutation<void, string>({
            query: (tag_name) => ({
                url: `${baseURLTags}/${tag_name}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Tag"]
        }),
        updateTag: builder.mutation<void, IUpdateTagBody>({
            query: (dto) => ({
                url: `${baseURLTags}/${dto.tag_name}`,
                method: "PUT",
                body: dto
            }),
            invalidatesTags: ["Tag"]
        }),
        getSubculturesCsv: builder.query<File, ISubculturesAndTagsFilters>({
            query: (params) => ({
                method: "GET",
                params: params,
                url: `${baseURLSubcultures}/create-csv`,
                cache: "no-cache",
                responseHandler: async (response) => {
                    const file = new File([await response.blob()], "subcultures.csv")
                    saveAs(window.URL.createObjectURL(file), "subcultures.csv")
                }
            }),
            providesTags: ["Subculture"],
        }),
        getTagsCsv: builder.query<File, ISubculturesAndTagsFilters>({
            query: (params) => ({
                method: "GET",
                params: params,
                url: `${baseURLTags}/create-csv`,
                cache: "no-cache",
                responseHandler: async (response) => {
                    const file = new File([await response.blob()], "tags.csv")
                    saveAs(window.URL.createObjectURL(file), "tags.csv")
                }
            }),
            providesTags: ["Tag"],
        }),
    })
})
