import { PublicView } from "./views/Public/PublicView";
import "./assets/css/main.css"
import { InnerView } from "./views/Inner/InnerView";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInitialized } from "./store/app/app";
import { Cookie } from "./utils/cookie";
import { setJwt } from "./store/account/account";
import { appSelectors } from "./store/app/selectors";
import { useGetCurrentUser } from "./hooks/account";


export const App = () => {
  const initialized = useSelector(appSelectors.initialized)

  const dispatch = useDispatch()
  const access_token = new Cookie("access_token").getValue()
  const { data: user, isSuccess, isError } = useGetCurrentUser()

  localStorage.setItem('user', JSON.stringify(user))

  useEffect(() => {
    if (access_token) {
      dispatch(setJwt({ access_token: access_token }))
    }
  }, [access_token])

  useEffect(() => {
    if (!access_token) {
      dispatch(setInitialized({ initialized: true }))
    }
  }, [])

  useEffect(() => {
    if (isSuccess || isError) {
      dispatch(setInitialized({ initialized: true }))
    }

    // if (isSuccess) {
    //   window.addEventListener("beforeunload", setLastVisitHandler)
    // }
  }, [isSuccess, isError])

  // useEffect(() => {
  //   if (!access_token) {
  //     window.removeEventListener("beforeunload", setLastVisitHandler)
  //   }
  // }, [access_token])

  return (

    !initialized ?
      <>
      </>
      :
      <>
        {
          user && access_token ?
            <InnerView />
            :
            <PublicView/>
        }
      </ >


  )
}