import React, {useMemo} from 'react'
import Select from 'react-select'
import {MultiValue} from 'react-select/dist/declarations/src'
import {ISelectOption} from '../../../types/ui'

function getValue<T, K extends keyof T>(data: T, key: K): string {
    return data[key] as string;
}

export function BuildISelectOptionArrayFromStructure<T extends {}>(arr: Array<T>, field_name: string): Array<ISelectOption> {
    return BuildISelectOptionArray(arr.map(
        item => getValue(item, field_name as keyof T)
    ))
}

export function BuildISelectOptionArray<T extends {}>(arr: Array<string>): Array<ISelectOption> {
    return arr.map(
        value => ({label: value, value: value})
    )
}

interface IProps {
    options: Array<ISelectOption>
    currentValue: Array<ISelectOption>
    onChange: (value: string | null) => void
}

export const Multiselect: React.FC<IProps> = React.memo(({options, currentValue, onChange}) => {
    const onChangeHandler = (e: MultiValue<ISelectOption>) => {
        if (!e.length) {
            onChange(null)
            return
        }

        onChange(e.map(item => item.value).join(","))
    }

    return (
        <Select
            placeholder="Выберите"
            options={options} isMulti={true}
            onChange={onChangeHandler} value={currentValue}
        />
    )
})


export const BuildMultiselect = <T extends {}>(
    items: Array<T> | undefined,
    filter_items_string: string | undefined | null,
    onChange: (value: string | null) => void,
    label: string,
    field_name: string
) => {
    const selectValues = useMemo(() => {
        return BuildISelectOptionArray(filter_items_string?.split(",") || [])
    }, [filter_items_string])
    const options = useMemo(() => {
        return BuildISelectOptionArrayFromStructure(items || [], field_name)
    }, [items, field_name])

    return (
        <div className="main-filters__col">
            <div className="main-filters__input-wrap main-filters__input-wrap input-wrap input-wrap_small-mob">
                <label className="top-label">{label}</label>
                <div className="select select_small">
                    {items && selectValues &&
                        <Multiselect currentValue={selectValues} options={options} onChange={onChange}/>}
                </div>
            </div>
        </div>
    )
}
