import {IUser} from "../types/users";
import {UserRightsEnum} from "../constants/api";

export const has_rights = (user: IUser | null | undefined, rights: UserRightsEnum) : boolean => {
    return user !== null && user !== undefined && user.rights.valueOf() >= rights.valueOf()
}

export const is_moderator = (user: IUser | null | undefined) : boolean => {
    return has_rights(user, UserRightsEnum.moderator)
}

export const is_admin = (user: IUser | null | undefined) : boolean => {
    return has_rights(user, UserRightsEnum.admin)
}