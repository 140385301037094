import { ITechnicalSupportMessage } from './../types/technicalSupport.d';
import { baseAPI } from './base';

// const baseURL = "/technical-support"

// export const technicalSupportAPI = baseAPI.injectEndpoints({
//     endpoints: (builder) => ({
//         sendMessage: builder.mutation<void, ITechnicalSupportMessage>({
//             query: (body) => ({
//                 url: `${baseURL}`,
//                 body,
//                 method: "POST"
//             })
//         })
//     })
// })


const baseURL = "/support/send_ticket"

export const technicalSupportAPI = baseAPI.injectEndpoints({
    endpoints: (builder) => ({
        sendMessage: builder.mutation<void, ITechnicalSupportMessage & { file: File | null }>({
            query: ({ file, ...formData }) => {
                console.log("🚀 ~ file:", file)
                const form = new FormData();

             
                Object.entries(formData).forEach(([key, value]) => {
                    form.append(key, value as string);
                });

    
                if (file) {
                    console.log("🚀 ~ file:", file)
                    form.append('file', file);
                }

                return {
                    url: `${baseURL}`,
                    body: form,
                    method: "POST",
                    headers: {
                   
                        'Content-Type': undefined
                    }
                };
            }
        })
    })
});
