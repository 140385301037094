import { cloneDeep } from 'lodash';
import { SortDirection } from './../../../types/filters.d';
import { ITiktokUsersFilters, SortByUsers } from './../../../types/tiktok.d';
import { IPagination } from './../../../types/pagination.d';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const baseFilters = {
    tags: null as string | null,
    subcultures: null as string | null,
    keyword: "",
    sort_field: "username" as SortByUsers,
    sort_direction: "asc" as SortDirection,
    follower_count_min: 0,
    follower_count_max: 10000000,
    video_count_min: 0,
    video_count_max: 15000,
    dateAt: null,
    dateTo: null,
    isChecked: false
} as ITiktokUsersFilters

const initialState = {
    filters: cloneDeep(baseFilters),
    currentFilters: cloneDeep(baseFilters),
    pagination: {
        page: 1,
        perPage: 20
    } as IPagination,
    selectedUsers: [] as Array<string>

}

export const tiktokUsersSlice = createSlice({
    name: "tiktokUsers",
    initialState,
    reducers: {
        setFiltersTags(state, action: PayloadAction<{ tags: string | null }>) {
            state.filters.tags = action.payload.tags
        },
        setFiltersSubcultures(state, action: PayloadAction<{ subcultures: string | null }>) {
            state.filters.subcultures = action.payload.subcultures
        },
        setFiltersKeyword(state, action: PayloadAction<{ keyword: string }>) {
            state.filters.keyword = action.payload.keyword
        },
        setFiltersSortBy(state, action: PayloadAction<{ sort_field: SortByUsers }>) {
            state.filters.sort_field = action.payload.sort_field
        },
        setFiltersSortDirection(state, action: PayloadAction<{ sort_direction: SortDirection }>) {
            state.filters.sort_direction = action.payload.sort_direction
        },
        setFiltersSubscribersCountMin(state, action: PayloadAction<{ follower_count_min: number }>) {
            state.filters.follower_count_min = action.payload.follower_count_min
        },
        setFiltersSubscribersCountMax(state, action: PayloadAction<{ follower_count_max: number }>) {
            state.filters.follower_count_max = action.payload.follower_count_max
        },
        setFiltersPostsCountMin(state, action: PayloadAction<{ video_count_min: number }>) {
            state.filters.video_count_min = action.payload.video_count_min
        },
        setFiltersPostsCountMax(state, action: PayloadAction<{ video_count_max: number }>) {
            state.filters.video_count_max = action.payload.video_count_max
        },
        setFiltersIsChecked(state, action: PayloadAction<{ isChecked: boolean }>) {
            state.currentFilters.isChecked = action.payload.isChecked
            state.filters.isChecked = action.payload.isChecked
        },
        setFiltersDateAt(state, action: PayloadAction<{ dateAt: Date | null }>) {
            state.filters.dateAt = action.payload.dateAt?.getTime() ?? null
        },
        setFiltersDateTo(state, action: PayloadAction<{ dateTo: Date | null }>) {
            state.filters.dateTo = action.payload.dateTo?.getTime() ?? null
        },
        setCurrentFilters(state, action: PayloadAction<{ currentFilters: ITiktokUsersFilters }>) {
            state.currentFilters = { ...action.payload.currentFilters }
        },
        clearFilters(state) {
            state.filters = cloneDeep(baseFilters)
        },
        setPaginationPage(state, action: PayloadAction<{ page: number }>) {
            state.pagination.page = action.payload.page
        },
        setPaginationPerPage(state, action: PayloadAction<{ perPage: number }>) {
            state.pagination.perPage = action.payload.perPage
        },
        setRankFilter(state, action: PayloadAction<{ ranks: string | null }>) {
            state.filters.ranks = action.payload.ranks
        },
        toggleSelectedUser(state, action: PayloadAction<{ user_id: string, isSelected?: boolean }>) {
            const user_id = action.payload.user_id
            const isSelected = action.payload.isSelected

            if (isSelected !== true && state.selectedUsers.includes(user_id)) {
                state.selectedUsers.splice(state.selectedUsers.indexOf(user_id), 1)
            } else if (!state.selectedUsers.includes(user_id)) {
                state.selectedUsers.push(user_id)
            }
        },
        clearSelectedUsers(state) {
            state.selectedUsers = []
        }
    }
})

export const {
    setFiltersTags, setCurrentFilters, clearFilters, setFiltersSubcultures, setFiltersPostsCountMax, setFiltersPostsCountMin,
    setFiltersSubscribersCountMax, setFiltersSubscribersCountMin, setPaginationPage, setPaginationPerPage, setFiltersKeyword,
    setFiltersSortBy, setFiltersSortDirection, setFiltersDateAt, setFiltersDateTo, toggleSelectedUser, clearSelectedUsers, 
    setFiltersIsChecked,setRankFilter } = tiktokUsersSlice.actions 