import {saveAs} from 'file-saver';
import {IInfographicsData} from '../types/infographics';
import {IPage} from '../types/page';
import {IPagination} from './../types/pagination.d';
import {
    IVkUser,
    IVkGroupsFilters,
    IVkUsersFilters,
    IVkGroup,
    IVkRegion,
    IVkCity,
    IVkCountry,
    IVkPost,
    IVkPostsFilters,
    IVkInfographicsUsersFilters,
    IVkInfographicsGroupsFilters,
    IVkInfographicsPostsFilters,
    IVkUniversity,
} from './../types/vk.d';
import {baseAPI} from './base';

const baseAPIEnhanced = baseAPI.enhanceEndpoints({addTagTypes: ["User", "Group"]})
const baseURL = "/vk"

const baseURLGroups = baseURL + "/groups"
const baseURLUsers = baseURL + "/users"
const baseURLPosts = baseURL + "/posts"

// api/vk/groups/<group_id>/users

interface IGetVkLocationItems<T> {
    response?: {
        count: number
        items: Array<T>
    }
}

export const vkAPI = baseAPIEnhanced.injectEndpoints({
    endpoints: (builder) => ({
        getVkGroups: builder.query<IPage<IVkGroup>, IVkGroupsFilters & IPagination>({ // получение всех групп\сообществ
            query: (params) => ({
                url: `${baseURLGroups}`,
                method: "GET",
                params: params
            }),
            providesTags: ["Group"]
        }),
        getVkGroupsDeleted: builder.query<IPage<IVkGroup>, IVkGroupsFilters & IPagination>({ // полуение удаленных групп/сообществ
            query: (params) => ({
                url: `${baseURLGroups}/deleted`,
                method: "GET",
                params: params
            }),
            providesTags: ["Group"]
        }),
        getVkGroupsAvailable: builder.query<IPage<IVkGroup>, IVkGroupsFilters & IPagination>({ // получение всех групп\сообществ
            query: (params) => ({
                url: `${baseURLGroups}/available`,
                method: "GET",
                params: params
            }),
            providesTags: ["Group"]
        }),
        getVkGroupsSearch: builder.query<IPage<IVkGroup>, {user: string} & IPagination>({
            query: (params) => ({
                url: `${baseURLGroups}/search`,
                method: "GET",
                params: params
            }),
            providesTags: ["Group"]
        }),
        getVkGroupById: builder.query<IVkGroup | null, number>({ // получение данных об одной группе\сообщетво
            query: (id) => ({
                url: `${baseURLGroups}/${id}`,
                method: "GET",
            }),
            providesTags: ["Group"]
        }),
        getVkUserById: builder.query<IVkUser | null, number>({   // тут инфо одного пользователя
            query: (id) => ({
                url: `${baseURLUsers}/${id}`,
                method: "GET",
            }),
            providesTags: ["User"]
        }),
        getVkUsers: builder.query<IPage<IVkUser>, IVkUsersFilters & IPagination>({  // тут все пользователи
            query: (params) => ({
                url: `${baseURLUsers}`,
                params: params
            }),
            providesTags: ["Group", "User"]
        }),
        getVkUsersCompare: builder.query<IPage<IVkUser>, {group: string} & IPagination>({
            query: (params) => ({
                url: `${baseURLUsers}/compare`,
                params: params
            }),
            providesTags: ["Group", "User"]
        }),
        getVkUsersFromCsv: builder.query<IPage<IVkUser>, { file: File } & IPagination>({ 
            async queryFn(args, {getState, dispatch}, extraOptions, fetchWithBQ) {
                const formData = new FormData()
                formData.append('file', args.file)
                const result = await fetchWithBQ({
                    url: baseURLUsers + '/csv?' + new URLSearchParams({
                        page: String(args.page),
                        count: String(args.perPage),
                    }),
                    method: 'POST',
                    body: formData,
                })
                const data = result.data as IPage<IVkUser>
                return {data}
            },
            providesTags: ["Group", "User"]
        }),
        getVkCountries: builder.query<Array<IVkCountry> | undefined, void>({ // получение всех стран
            query: () => ({
                url: `${baseURL}/countries`,
            }),
            //transformResponse: (body: IGetVkLocationItems<IVkCountry>) => body.response?.items
        }),
        getVkUniversities: builder.query<Array<IVkUniversity> | undefined, string>({  // получение всех университетов
            query: (country: string) => ({
                url: `${baseURL}/universities`,
                params: {
                    country: country
                }
            }),
            //transformResponse: (body: IGetVkLocationItems<IVkUniversity>) => body.response?.items
        }),
        getVkCities: builder.query<Array<IVkCity> | undefined, string>({  // получение всех городов
            query: (country: string) => ({
                url: `${baseURL}/cities`,
                params: {
                    country: country
                }
            }),
            //transformResponse: (body: IGetVkLocationItems<IVkCity>) => body.response?.items
        }),
        getVkRegions: builder.query<Array<IVkRegion> | undefined, string>({  // получение всех регионов
            query: (country: string) => ({
                url: `${baseURL}/regions`,
                params: {
                    country: country
                }
            }),
            //transformResponse: (body: IGetVkLocationItems<IVkRegion>) => body.response?.items
        }),
        getVkSchools: builder.query<Array<any> | undefined, string>({  
            query: (country: string) => ({
                url: `${baseURL}/schools`,
                params: {
                    country: country 
                }
            }),
        }),
        
        getVkUsersCsv: builder.query<File, IVkUsersFilters>({  // скачивание пользователей
            query: (params) => ({
                params: params,
                url: `${baseURLUsers}/export`,
                cache: "no-cache",
                responseHandler: async (response) => {
                    const file = new File([await response.blob()], "vk-users.csv")
                    saveAs(window.URL.createObjectURL(file), "vk-users.csv")
                }
            }),
            providesTags: ["User"],
        }),
        getVkGroupsCsv: builder.query<File, IVkGroupsFilters>({  // скачивание групп\сообществ
            query: (params) => ({
                params: params,
                url: `${baseURLGroups}/export`,
                cache: "no-cache",
                responseHandler: async (response) => {
                    const file = new File([await response.blob()], "vk-groups.csv")
                    saveAs(window.URL.createObjectURL(file), "vk-groups.csv")
                }
            }),
            providesTags: ["Group"],
        }),
        getVkUsersCompareCsv: builder.query<File, {group: string} & IVkUsersFilters>({  // скачивание пользователей
            query: (params) => ({
                params: params,
                url: `${baseURLUsers}/compare/export`,
                cache: "no-cache",
                responseHandler: async (response) => {
                    const file = new File([await response.blob()], "vk-users-compare.csv")
                    saveAs(window.URL.createObjectURL(file), "vk-users-compare.csv")
                }
            }),
            providesTags: ["User"],
        }),
        getVkGroupsSearchCsv: builder.query<File, {user: string} & IVkGroupsFilters>({  // скачивание групп\сообществ
            query: (params) => ({
                params: params,
                url: `${baseURLGroups}/search/export`,
                cache: "no-cache",
                responseHandler: async (response) => {
                    const file = new File([await response.blob()], "vk-groups-search.csv")
                    saveAs(window.URL.createObjectURL(file), "vk-groups-search.csv")
                }
            }),
            providesTags: ["Group"],
        }),
        // getVkPosts: builder.query<IPage<IVkPost>, Partial<IPagination & IVkPostsFilters>>({
        //     query: (filters) => ({
        //         url: `${baseURLPosts}`,
        //         params: filters
        //     })
        // }),
        //отображение всех постов у группы
        getVkGroupPosts: builder.query<IPage<IVkPost>, Partial<IPagination & IVkPostsFilters>>({  // получение постов из групп
            query: (params) => ({
                url: `${baseURLGroups}/${params.group_id}/posts`,
                method: "GET",
                params: params
            }),
        }),

        // удаление

        deleteVkGroup: builder.mutation<void, number>({
            query: (id) => ({
                url: `${baseURLGroups}/bulk_delete`,
                method: "DELETE",
                body: [id]
            }),
            invalidatesTags: ["Group", "User"]
        }),
        deleteVkGroups: builder.mutation<void, Array<number>>({
            query: (ids) => ({
                url: `${baseURLGroups}/bulk_delete`,
                method: "DELETE",
                body: ids
            }),
            invalidatesTags: ["Group"]
        }),
        checkVkGroup: builder.mutation<void, number>({
            query: (id) => ({
                url: `${baseURLGroups}/check/${id}`,
                method: "PUT"
            }),
            invalidatesTags: ["Group"]
        }),
        bookVkGroup: builder.mutation<void, number>({
            query: (id) => ({
                url: `${baseURLGroups}/${id}/book`,
                method: "POST"
            }),
            invalidatesTags: ["Group"]
        }),
        requestBlockVkGroup: builder.mutation<void, number>({
            query: (id) => ({
                url: `${baseURLGroups}/${id}/block_request`,
                method: "POST"
            }),
            invalidatesTags: ["Group"]
        }),
        requestUnblockVkGroup: builder.mutation<void, number>({
            query: (id) => ({
                url: `${baseURLGroups}/${id}/unblock_request`,
                method: "POST"
            }),
            invalidatesTags: ["Group"]
        }),
        unbookVkGroup: builder.mutation<void, number>({
            query: (id) => ({
                url: `${baseURLGroups}/${id}/unbook`,
                method: "POST"
            }),
            invalidatesTags: ["Group"]
        }),
        deleteVkUser: builder.mutation<void, number>({
            query: (id) => ({
                url: `${baseURLUsers}/delete/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["User"]
        }),
        deleteVkUsers: builder.mutation<void, Array<number>>({
            query: (ids) => ({
                url: `${baseURLUsers}/delete/bulk`,
                method: "DELETE",
                body: ids
            }),
            invalidatesTags: ["User"]
        }),


        // восстановление

        restoreVkGroup: builder.mutation<void, number>({  // Восстановалние одной группы
            query: (id) => ({
                url: `${baseURLGroups}/bulk_restore`,
                method: "POST",
                body: [id]
            }),
            invalidatesTags: ["Group", "User"]
        }),
        restoreVkGroups: builder.mutation<void, Array<number>>({  // Восстановалние групп
            query: (ids) => ({
                url: `${baseURLGroups}/bulk_restore`,
                method: "POST",
                body: ids
            }),
            invalidatesTags: ["Group"]
        }),

        // проверка пользователя

        checkVkUser: builder.mutation<void, number>({
            query: (id) => ({
                url: `${baseURLUsers}/check/${id}`,
                method: "PUT"
            }),
            invalidatesTags: ["User"]
        }),
        getVkUsersCountBySubcultures: builder.query<Array<IInfographicsData>, Partial<IVkInfographicsUsersFilters>>({
            query: (params) => ({
                url: `${baseURLUsers}/count-by-subcultures`,
                params: params
            })
        }),
        getVkGroupsCountBySubcultures: builder.query<Array<IInfographicsData>, Partial<IVkInfographicsGroupsFilters>>({
            query: (params) => ({
                url: `${baseURLGroups}/count-by-subcultures`,
                params: params

            })
        }),
        getVkPostsCountBySubcultures: builder.query<Array<IInfographicsData>, Partial<IVkInfographicsPostsFilters>>({
            query: (params) => ({
                url: `${baseURLPosts}/count-by-subcultures`,
                params: params

            })
        }),
        postVkUploadCsv: builder.mutation({
            query: (blob) => ({
                url: `${baseURL}/upload-csv`,
                method: 'POST',
                body: blob,
            }),
            invalidatesTags: ["User"]
        }),

        postVkStatus: builder.mutation<any, { group_id: number, status: string }>({
            query: ({ group_id, status }) => ({
                url:  `/vk/groups/${group_id}/set_threat_assessment_status/${status}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ status })
            })
        }),
    })
})
