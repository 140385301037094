import { cloneDeep } from 'lodash';
import { SortDirection, SortDirectionRank } from './../../../types/filters.d';
import { IVkUsersFilters, SortBy } from './../../../types/vk.d';
import { IPagination } from './../../../types/pagination.d';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const baseFilters = {
    tags: null as string | null,
    subcultures: null as string | null,
    keyword: "",
    region: null,
    cities: null,
    country: null,
    sort_field: null,
    sort_direction: "asc" as SortDirection,
    dateAt: null,
    dateTo: null,
    isChecked: false,
    universities: null,
    uploaded: false,
    ageStart: 0,
    ageEnd: 150,
    ranks: null,
    only_active: false,
    schools: null,
} as IVkUsersFilters

const initialState = {
    filters: cloneDeep(baseFilters),
    currentFilters: cloneDeep(baseFilters),
    pagination: {
        page: 1,
        perPage: 20
    } as IPagination,
    selectedUsers: [] as Array<number>
}

export const vkUsersSlice = createSlice({
    name: "vkUsers",
    initialState,
    reducers: {
        setFiltersTags(state, action: PayloadAction<{ tags: string | null }>) {
            state.filters.tags = action.payload.tags
        },
        setFiltersSubcultures(state, action: PayloadAction<{ subcultures: string | null }>) {
            state.filters.subcultures = action.payload.subcultures
        },
        setFiltersKeyword(state, action: PayloadAction<{ keyword: string }>) {
            state.filters.keyword = action.payload.keyword
        },
        setFiltersCity(state, action: PayloadAction<{ cities: string | null }>) {
            state.filters.cities = action.payload.cities
        },
        setFiltersUniversity(state, action: PayloadAction<{ universities: string | null }>) {
            state.filters.universities = action.payload.universities
        },
        setFiltersSchools(state, action: PayloadAction<{ schools: string | null }>) {
            state.filters.schools = action.payload.schools
        },
        setFiltersDistrict(state, action: PayloadAction<{ region: string | null }>) {
            state.filters.region = action.payload.region
        },
        setFiltersCountry(state, action: PayloadAction<{ country: string | null }>) {
            state.filters.country = action.payload.country
        },
        setFiltersAgeStart(state, action: PayloadAction<{ ageStart: number | 0 }>) {
            state.filters.ageStart = action.payload.ageStart
        },
        setFiltersAgeEnd(state, action: PayloadAction<{ ageEnd: number | 150 }>) {
            state.filters.ageEnd = action.payload.ageEnd
        },
        setFiltersSortBy(state, action: PayloadAction<{ sort_field: SortBy }>) {
            state.filters.sort_field = action.payload.sort_field
        },
        setCurrentFilters(state, action: PayloadAction<{ currentFilters: IVkUsersFilters }>) {
            state.currentFilters = { ...action.payload.currentFilters }
        },
        setFiltersSortDirection(state, action: PayloadAction<{ sort_direction: SortDirection }>) {
            state.filters.sort_direction = action.payload.sort_direction
        },
        setFiltersDateAt(state, action: PayloadAction<{ dateAt: Date | null }>) {
            state.filters.dateAt = action.payload.dateAt?.getTime() ?? null
        },
        setFiltersIsChecked(state, action: PayloadAction<{ isChecked: boolean }>) {
            state.currentFilters.isChecked = action.payload.isChecked
            state.filters.isChecked = action.payload.isChecked
        },
        setFiltersDateTo(state, action: PayloadAction<{ dateTo: Date | null }>) {
            state.filters.dateTo = action.payload.dateTo?.getTime() ?? null
        },
        setOnlyActive(state, action: PayloadAction<{only_active: Boolean | null}>) {
            state.filters.only_active = action.payload.only_active
        },
        setFiltersUploaded(state, action: PayloadAction<{ uploaded: boolean }>) {
            state.filters.uploaded = action.payload.uploaded
        },
        clearFilters(state) {
            state.filters = cloneDeep(baseFilters)
        },
        setPaginationPage(state, action: PayloadAction<{ page: number }>) {
            state.pagination.page = action.payload.page
        },
        setRankFilter(state, action: PayloadAction<{ ranks: string | null }>) {
            state.filters.ranks = action.payload.ranks
        },
        setPaginationPerPage(state, action: PayloadAction<{ perPage: number }>) {
            state.pagination.perPage = action.payload.perPage
        },
        toggleSelectedUser(state, action: PayloadAction<{ id: number, isSelected?: boolean }>) {
            const id = action.payload.id
            const isSelected = action.payload.isSelected

            if (isSelected !== true && state.selectedUsers.includes(id)) {
                state.selectedUsers.splice(state.selectedUsers.indexOf(id), 1)
            } else if (!state.selectedUsers.includes(id)) {
                state.selectedUsers.push(id)
            }
        },
        clearSelectedUsers(state) {
            state.selectedUsers = []
        }
    }
})

export const {
    setFiltersTags, setFiltersSubcultures, setPaginationPage, setPaginationPerPage, setFiltersCity, setFiltersCountry,
    setFiltersDistrict, setFiltersKeyword, setFiltersSortBy, setFiltersSortDirection, clearFilters, setCurrentFilters,
    setFiltersDateAt, setFiltersDateTo, toggleSelectedUser, clearSelectedUsers, setFiltersIsChecked, setFiltersUniversity,
    setFiltersUploaded, setFiltersAgeStart,setFiltersAgeEnd, setOnlyActive, setRankFilter, setFiltersSchools
} = vkUsersSlice.actions 
