import React, { useMemo, useState } from 'react'
import { IAction } from '../../../../types/tables'
import { TableAction } from './TableAction'
import {useGetCurrentUser} from "../../../../hooks/account";
import {UserRightsEnum} from "../../../../constants/api";
import {has_rights} from "../../../../utils/rights";

// interface IProps extends React.PropsWithChildren {
//     actions?: Array<IAction>
// }

// export const MoreBtn: React.FC<IProps> = React.memo(({ actions }) => {
//     const { data: user } = useGetCurrentUser()
//     const [isActive, setIsActive] = useState<boolean>(false)

//     return (
//         <>
//             {
//                 !!actions.length && <div className="mob-actions js-nav">
//                     <button className="dots-button js-nav-button" onClick={() => setIsActive(!isActive)} type="button"></button>
//                     {
//                         isActive &&
//                         <div className="status-nav">
//                             {
//                                 actions.filter(item => has_rights(user, item.rights || UserRightsEnum.user)).map((action, index) => <TableAction
//                                     actionClass={action.class} key={index}
//                                     callback={action.callback} text={action.text} />)
//                             }
//                         </div>
//                     }
//                 </div >
//             }
//         </>
//     )
// })

interface IProps extends React.PropsWithChildren {
    actions?: Array<IAction>;
  }
  
  export const MoreBtn: React.FC<IProps> = React.memo(({ actions }) => {
    const { data: user } = useGetCurrentUser();
    const [isActive, setIsActive] = useState<boolean>(false);
  
    // Фильтруем действия на основе прав пользователя и устанавливаем значение по умолчанию []
    const filteredActions = actions?.filter(
      (item) => has_rights(user, item.rights || UserRightsEnum.user)
    ) || [];
  
    // Функция для переключения активности
    const toggleActive = () => {
      setIsActive(!isActive);
    };
  
    return (
      <>
        {filteredActions.length > 0 && (
          <div className="mob-actions js-nav">
            <button
              className="dots-button js-nav-button"
              onClick={toggleActive}
              type="button"
            ></button>
            {isActive && (
              <div className="status-nav">
                {filteredActions.map((action, index) => (
                  <TableAction
                    actionClass={action.class}
                    key={index}
                    callback={action.callback}
                    text={action.text}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </>
    );
  });