import React from 'react'
import { TwitchStream } from '../TwitchStream'

// тут мапаются стримы и выводятся стримы на фронт

interface IProps {
//   channels: Array<IYouTubeChannel> 
    channels: Array<any> 
    selectedChannels: Array<number>
    isAllToggled: boolean
    setIsAllToggledHandler: () => void
    toggleSelectedChannelHandler: (id: number) => void
    onDelete: (id: number) => void
    onCheck: (id: number) => void
    onBook: (id: number) => void
    onUnBook: (id: number) => void
  // defaultChecked: boolean

}

export const TwitchStreamsTable: React.FC<IProps> = React.memo((props) => {
  const {
    channels, selectedChannels, isAllToggled,
    setIsAllToggledHandler, toggleSelectedChannelHandler, 
    onDelete, onCheck, onBook, onUnBook
  } = props

  return (
    <div className="table table_youtube">
      <div className="table__main">
        <div className="table__head">

          <div className="table__head-row">
            <div className="table__head-cell table__head-cell_22 table__head-cell_mob-top table__head-cell_check" data-head>
              <div className="table__checkbox checkbox">
                <label className="checkbox__label">
                  <input checked={isAllToggled} onChange={setIsAllToggledHandler} className="checkbox__input" type="checkbox" />
                  <span className="checkbox__icon"></span>
                </label>
              </div>
              <span className="table__label">Название канала</span>
            </div>
            {/* <div className="table__head-cell table__head-cell_23 table__head-cell_hide-tablet">
              <span className="table__label">Описание</span>
            </div> */}
            <div className="table__head-cell table__head-cell_21 table__head-cell_hide-tablet">
              <span className="table__label">Страна<br />профиля</span>
            </div>
            <div className="table__head-cell table__head-cell_22 table__head-cell_hide-tablet">
              <span className="table__label">Дата<br />регистрации</span>
            </div>
            {/* <div className="table__head-cell table__head-cell_23 table__head-cell_hide-tablet">
              <span className="table__label">Дата последней<br /> добавленной публикации</span>
            </div>
            <div className="table__head-cell table__head-cell_24 table__head-cell_center" data-head>
              <span className="table__label">Подписчики<br />месяц (всего)</span>
            </div>
            <div className="table__head-cell table__head-cell_26 table__head-cell_center" data-head>
              <span className="table__label">Колличество<br />видео (всего)</span>
            </div>
            <div className="table__head-cell table__head-cell_27 table__head-cell_center" data-head>
              <span className="table__label">Просмотры<br />месяц (всего)</span>
            </div> */}
            {/*<div className="table__head-cell table__head-cell_28" data-head>*/}
            {/*  <span className="table__label">Реакции<br />месяц (всего)</span>*/}
            {/*</div>*/}
            <div className="table__head-cell table__head-cell_29 table__head-cell_hide-tablet">
              <span className="table__label">Теги</span>
            </div>
            <div className="table__head-cell table__head-cell_30 table__head-cell_hide-tablet">
              <span className="table__label">Субкультуры</span>
            </div>
            {/* <div className="table__head-cell table__head-cell_30 table__head-cell_hide-tablet">
              <span className="table__label">Ранг</span>
            </div> */}
            <div className="table__head-cell table__head-cell_31 table__head-cell_mob-top" data-head>
              <span className="table__label">Действия</span>
            </div>
          </div>

        </div>
        <div className="table__body">
          {/* / TODO: поправить типизацию / */}
          {(channels || []).map((channel: any, index) => <TwitchStream
          isSelected={selectedChannels.find((id: number) => id === channel.channel_id) !== undefined ? true : false}
          toggleSelectedChannel={toggleSelectedChannelHandler}
          channel={channel} key={index} 
          onDelete={onDelete}
          onCheck={onCheck}
          onBook={onBook}
          onUnBook={onUnBook}
          />)}
        </div>
      </div>
    </div>
  )
})
