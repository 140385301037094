import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { youtubeAPI } from '../../../api/youtube'
import { TableActions } from '../../../components/Common/UI/Table/TableActions'
import { Pagination } from '../../../components/Inner/Pagination'
import { useTypedDispatch } from '../../../hooks/common'
import { IPermissibleSortBy, SortDirection } from '../../../types/filters'
import {UserRightsEnum} from "../../../constants/api";
import Loader from '../../../components/Common/UI/Table/Loader'
import { TwitchStreamsTable } from '../../../components/Inner/Twitch/TwitchTable/TwutchStreamsTable'
import { twitchAPI } from '../../../api/twitch'
import { twitchStreamsSelectors } from '../../../store/twitch/selectors'
import { SortBy } from '../../../types/twitch'
import { TwitchFilters } from '../../../components/Inner/Twitch/TwitchFilters'
import { setPaginationPage, 
    setFiltersKeyword,
    setCurrentFilters,
    clearFilters,
    setFiltersSortDirection,
    setFiltersSortBy,
    setFiltersSubscribersCountMin,
    setFiltersSubscribersCountMax,
    setFiltersPostsCountMin,
    setFiltersPostsCountMax,
    setFiltersSubcultures,
    setFiltersCountries,
    setFiltersDateAt,
    setFiltersDateTo,
    setFiltersTags,
    toggleSelectedChannel,
    clearSelectedChannels,
 } from '../../../store/twitch/reducer'

const permissibleSortBy = [
    { 
        code: "stream_name", text: "По названию",
    },
    // { 
    //     code: "follower_count", text: "Общее количество подписчиков",
    // },
    // { 
    //     code: "video_count", text: "Общее количество видео",
    // },
    // { 
    //     code: "last_post_date", text: "Дата последней добавленной публикации",
    // }
] as Array<IPermissibleSortBy>

export const TwitchStreams = () => {


    const filters = useSelector(twitchStreamsSelectors.filters)
    const currentFilters = useSelector(twitchStreamsSelectors.currentFilters)
    const pagination = useSelector(twitchStreamsSelectors.pagination)
    const selectedChannels = useSelector(twitchStreamsSelectors.selectedChannels)

    const {data: streams, isLoading}: any = twitchAPI.useGetTwitchChannelsQuery({...pagination, ...currentFilters})
    console.log("🚀 ~ TwitchStreams ~ streams:", streams)

    // TODO: remove or change to correct format
    const [getCsv] = youtubeAPI.useLazyGetYouTubeChannelsCsvQuery()
    const [deleteChannel] = youtubeAPI.useDeleteYouTubeChannelMutation()
    const [checkChannel] = youtubeAPI.useCheckYouTubeChannelMutation()
    const [deleteChannels, { isSuccess: isSuccessDeleteChannels }] = youtubeAPI.useDeleteYouTubeChannelsMutation()
    const [bookChannek] = youtubeAPI.useBookChannelMutation()
    const [unBookChannel] = youtubeAPI.useUnBookChannelMutation()

    const [isAllToggled, setIsAllToggled] = useState<boolean>(false)


    const channels = streams?.items || []
    const count = streams?.total || 0

    const dispatch = useTypedDispatch()

    const onChangePage = useCallback((page: number) => {
        dispatch(setPaginationPage({ page }))
    }, [filters])

    const onChangeKeyword = useCallback((keyword: string) => {
        dispatch(setFiltersKeyword({ keyword }))
    }, [])

    const applyFilters = useCallback(() => {
        dispatch(setPaginationPage({ page: 1 }))
        dispatch(setCurrentFilters({ currentFilters: { ...filters } }))
    }, [filters])

    const clearFiltersHandler = useCallback(() => {
        dispatch(clearFilters())
    }, [])

    const downloadCsv = useCallback(() => {
        getCsv({ ...filters })
    }, [filters])

    const onChangeSortDirection = useCallback((sort_direction: SortDirection) => {
        dispatch(setFiltersSortDirection({ sort_direction }))
    }, [])

    const onChangeSortBy = useCallback((sort_field: SortBy) => {
        dispatch(setFiltersSortBy({ sort_field }))
    }, [])

    const onChangeSubscribersCountMin = useCallback((min_followers: number) => {
        dispatch(setFiltersSubscribersCountMin({ min_followers }))
    }, [])

    const onChangeSubscribersCountMax = useCallback((max_followers: number) => {
        dispatch(setFiltersSubscribersCountMax({ max_followers }))
    }, [])

    const onDeleteChannel = useCallback((channel_id: number) => {
        deleteChannel(channel_id)
    }, [])

    const onCheckChannel = useCallback((channel_id: number) => {
        checkChannel(channel_id)
    }, [])

    const onBookСhannel = useCallback((channel_id: number) => {
        bookChannek(channel_id)
    }, [])

    const onUnBookChannel = useCallback((channel_id: number) => {
        unBookChannel(channel_id)
    }, [])   

    const onChangePostsCountMin = useCallback((min_videos: number) => {
        dispatch(setFiltersPostsCountMin({ min_videos }))
    }, [])

    const onChangePostsCountMax = useCallback((max_videos: number) => {
        dispatch(setFiltersPostsCountMax({ max_videos }))
    }, [])

    const onChangeSubcultures = useCallback((subcultures: string | null) => {
        dispatch(setFiltersSubcultures({ subcultures }))
        onChangeTags(null)
    }, [])

    const onChangeCountries = useCallback((countries: string | null) => {
        dispatch(setFiltersCountries({ countries }))
        onChangeTags(null)
    }, [])

    const onChangeDateAt = useCallback((dateAt: Date | null) => {
        dispatch(setFiltersDateAt({ dateAt }))
    }, [])

    const onChangeDateTo = useCallback((dateTo: Date | null) => {
        dispatch(setFiltersDateTo({ dateTo }))
    }, [])

    const onChangeTags = useCallback((tags: string | null) => {
        dispatch(setFiltersTags({ tags }))
    }, [])

    const toggleSelectedChannelHandler = useCallback((id: number) => {
        dispatch(toggleSelectedChannel({ id }))
    }, [])

    const setIsAllToggledHandler = () => {
        if (!channels) {
            return
        }

        for (let channel of channels) {
            if (isAllToggled) {
                dispatch(toggleSelectedChannel({ id: channel.channel_id, isSelected: false }))
            } else {
                dispatch(toggleSelectedChannel({ id: channel.channel_id, isSelected: true }))
            }
        }

        setIsAllToggled(!isAllToggled)
    }


    const deleteChannelsHandler = useCallback(() => {
        deleteChannels(selectedChannels)
    }, [selectedChannels])

    useEffect(() => {
        dispatch(clearSelectedChannels())
        setIsAllToggled(false)
    }, [isSuccessDeleteChannels])

    const tableActions = [{ text: "Удалить", class: "status-nav__link_red", callback: deleteChannelsHandler,  rights: UserRightsEnum.moderator }]


    return (
        <>
        {isLoading ? (<Loader/>): (
            <div>
                    <Helmet>
                        <title>Каналы</title>
                    </Helmet>
                    <TwitchFilters
                        onChangeSortBy={onChangeSortBy} onChangeSortDirection={onChangeSortDirection}
                        filters={filters} onChangeKeyword={onChangeKeyword} 
                        onDownloadCsv={downloadCsv}
                        onChangePostsCountMax={onChangePostsCountMax} onChangePostsCountMin={onChangePostsCountMin}
                        onChangeSubscribersCountMax={onChangeSubscribersCountMax} onChangeSubscribersCountMin={onChangeSubscribersCountMin}
                        onChangeSubcultures={onChangeSubcultures} onChangeTags={onChangeTags} applyFilters={applyFilters}
                        clearFilters={clearFiltersHandler} onChangeDateAt={onChangeDateAt} onChangeDateTo={onChangeDateTo}
                        permissibleSortBy={permissibleSortBy} onChangeCountries={onChangeCountries}
                    />
                    <TableActions 
                    actions={tableActions} 
                    isActive={!!selectedChannels.length} />

                    <div className="table-block">
                        <TwitchStreamsTable
                            toggleSelectedChannelHandler={toggleSelectedChannelHandler}
                            selectedChannels={selectedChannels} setIsAllToggledHandler={setIsAllToggledHandler} channels={channels}
                            isAllToggled={isAllToggled} 
                            onDelete={onDeleteChannel} onCheck={onCheckChannel} 
                            onBook={onBookСhannel}
                            onUnBook={onUnBookChannel}
                        />
                        <Pagination onChangePage={onChangePage} page={streams?.page} count={streams?.total ?? 0} perPage={streams?.page_size} /> 
                        {/* <Pagination onChangePage={onChangePage} page={pagination.page} count={count ?? 0} page_size={pagination.page_size} /> */}
                    </div>
            </div>
        )}
        </>
    )
}
