import React from 'react';
import '../../../../assets/css/loader.css'; 

const Loader = () => {
    return (
      <div className="loader-container">
        <div className="loader"></div>
        <p className="loading-text">Подождите, данные загружаются...</p>
      </div>
    );
  };

export default Loader;
