// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter {
    display: block;
}

.status-nav {
    display: block;
}

.hide-mob {
    display: block;
}

.search-filter__mob {
    display: none;
}

@media screen and (max-width: 767px) {
    .search-filter__mob {
        display: block;
    }
}

.select-component {
    height: 100%;
    width: 100%;
}

.posts__more {
    cursor: pointer;
}

.user-desc__more {
    cursor: pointer;
}

.h-100 {
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/fix.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".filter {\n    display: block;\n}\n\n.status-nav {\n    display: block;\n}\n\n.hide-mob {\n    display: block;\n}\n\n.search-filter__mob {\n    display: none;\n}\n\n@media screen and (max-width: 767px) {\n    .search-filter__mob {\n        display: block;\n    }\n}\n\n.select-component {\n    height: 100%;\n    width: 100%;\n}\n\n.posts__more {\n    cursor: pointer;\n}\n\n.user-desc__more {\n    cursor: pointer;\n}\n\n.h-100 {\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
