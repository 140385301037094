import {baseURL, pro} from '../constants/api';
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import {Cookie} from '../utils/cookie';
import {filterParams} from "../utils/params";
import {head} from "lodash";

export const baseAPI = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: (headers: Headers, {getState}) => {
            const access_token = new Cookie("access_token").getValue()
            if (access_token) {
                headers.set("Authorization", `Bearer ${access_token}`);
            }
            if (process.env.PRO) {
                headers.set("PRO", process.env.PRO)
            }
            return headers;
        },
        paramsSerializer: (params: Record<string, any>) => {
            return new URLSearchParams(filterParams(params)).toString()
        }
    }),
    endpoints: (builder) => ({})
})

