import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { technicalSupportAPI } from '../../../api/techniaclSupport'
import { ITechnicalSupportMessage } from '../../../types/technicalSupport'
import * as Yup from "yup"
import { emailValidation } from '../../../validations/user'
import { messageValidation } from '../../../validations/technicalSupport'
import classNames from 'classnames'
import { checkboxValidation } from '../../../validations/common'
import { SelectComponent } from "../../../components/Common/UI/SelectComponent"
import { accountAPI } from '../../../api/account'
import { ValidationInput } from '../../../components/Common/UI/ValidationInput'
import { workplaceValidation } from '../../../validations/user'
import fileLogo from "./../../../assets/img/svg/file.svg"

interface IProps {
    onSubmit: (values: ITechnicalSupportMessage) => void
}

const schema = Yup.object().shape({
    email: emailValidation,
    message: messageValidation,
    isAccept: checkboxValidation,
    workplace: workplaceValidation
});

// export const TechnicalSupportForm: React.FC<IProps> = ({ onSubmit }) => {
//     const { data: districts } = accountAPI.useGetDistrictsQuery();
//     const [file, setFile] = useState<File | null>(null);  // Состояние для файла

//     return (
//         <div className="login-page__content">
//             <div className="login-page__top login-page__top_support">
//                 <h1 className="login-page__title">Обращение в техническую поддержку</h1>
//                 <p className="login-page__text">Заполните поля, чтобы отправить обращение в техподдержку.</p>
//             </div>
//             <Formik
//                 initialValues={{
//                     email: "",
//                     contactDetails: "",
//                     message: "",
//                     isAccept: false,
//                     workPlace: ""
//                 }}
//                 validationSchema={schema}
//                 onSubmit={(values) => {
//                     console.log('onSubmit', values);
                    
//                     onSubmit({ ...values, file }); 
//                 }}
//             >
//                 {({ values, handleChange, setFieldValue, errors }) => (
//                     <Form>
//                         <div className="reg-form">
//                             <div className="reg-form__cols">
//                                 <div className="reg-form__col">
//                                     <div className="reg-form__input-wrap reg-form__input-wrap_support input-wrap input-wrap_small-mob">
//                                         <Field
//                                             value={values.email}
//                                             onChange={handleChange}
//                                             name="email"
//                                             className={classNames("text-input", {
//                                                 active: values.email,
//                                                 error: errors.email
//                                             })}
//                                             type="email"
//                                             id="support-email"
//                                         />
//                                         <label className="input-placeholder" htmlFor="support-email">
//                                             Ваш Email
//                                         </label>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="reg-form__cols">
//                                 <div className="reg-form__col">
//                                     <div className="reg-form__input-wrap reg-form__input-wrap_support input-wrap input-wrap_small-mob">
//                                         <Field
//                                             value={values.contactDetails}
//                                             onChange={handleChange}
//                                             name="contactDetails"
//                                             className={classNames("text-input", {
//                                                 active: values.contactDetails,
//                                                 error: errors.contactDetails
//                                             })}
//                                             id="support-contact-details"
//                                         />
//                                         <label className="input-placeholder" htmlFor="support-contact-details">
//                                             Вашe Ф.И.О.
//                                         </label>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="profile-form__col reg-form__cols">
//                                 <div className="profile-form__input-wrap input-wrap">
//                                     <label className="top-label">Регион</label>
//                                     <div className="select">
//                                         <Field
//                                             component={SelectComponent}
//                                             onChange={(newValue: string) => setFieldValue('district', newValue)} 
//                                             options={districts}
//                                             htmlName="district"
//                                         />
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="profile-form__col reg-form__cols">
//                                 <div className="profile-form__input-wrap input-wrap">
//                                     <label
//                                         className="top-label"
//                                         htmlFor="profile-position"
//                                     >
//                                         Должность
//                                     </label>
//                                     <Field
//                                         name="workPlace"
//                                         value={values.workPlace}
//                                         onChange={handleChange}
//                                         className={classNames("text-input", {
//                                             active: values.workPlace,
//                                             error: errors.workPlace
//                                         })}
//                                         id="profile-position"
//                                         type="text"
//                                     />
//                                 </div>
//                             </div>

//                             <div className="reg-form__cols">
//                                 <div className="reg-form__col">
//                                     <div className="reg-form__input-wrap reg-form__input-wrap_support input-wrap input-wrap_small-mob">
//                                         <Field
//                                             as="textarea"
//                                             value={values.message}
//                                             onChange={handleChange}
//                                             name="message"
//                                             className={classNames("textarea", {
//                                                 active: values.message,
//                                                 error: errors.message
//                                             })}
//                                             id="support-text"
//                                         />
//                                         <label className="input-placeholder" htmlFor="support-text">
//                                             Ваше обращение
//                                         </label>
//                                     </div>
//                                 </div>
//                             </div>

                
//                    <div className="reg-form__cols">
//                                 <div className="reg-form__col">
//                                     <div className="reg-form__input-wrap">
//                                         <label className="top-label" htmlFor="support-file">Прикрепить файл</label>
//                                         <input
//                                             id="support-file"
//                                             name="file"
//                                             type="file"
//                                             className="text-input"
//                                             onChange={(event) => {
//                                                 const file = event.currentTarget.files?.[0] || null;
//                                                 setFile(file); 
//                                                 console.log("Файл выбран:", file); 
//                                             }}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="reg-form__checkbox checkbox">
//                                 <label className="checkbox__label">
//                                     <Field
//                                         name="isAccept"
//                                         onChange={handleChange}
//                                         checked={values.isAccept}
//                                         className="checkbox__input"
//                                         type="checkbox"
//                                     />
//                                     <span className="checkbox__icon"></span>
//                                     <span className="checkbox__text">Согласен на обработку персональных данных</span>
//                                 </label>
//                             </div>

//                             <div className="reg-form__cols">
//                                 <div className="reg-form__col">
//                                     <button className="reg-form__button button" type="submit">
//                                         Отправить
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//                     </Form>
//                 )}
//             </Formik>
//         </div>
//     );
// };


export const TechnicalSupportForm: React.FC<IProps> = ({ onSubmit }) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [isAccept, setIsAccept] = useState(false);
    const [position, setPosition] = useState('');
    const [region_name, setRegionName] = useState('');
    const [topic, setTopic] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    
    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value)
    }
    // колличест во символов обращения
    const maxLengthMessage = 1000
    const maxLenghtTopic = 255


    function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
      const file = event.target.files?.[0]; 
      if (file) {
        if (file.size > 10 * 1024 * 1024) { 
          alert("Файл слишком большой.  Размер должен быть не более 10 МБ."); 
          event.target.value = "";
          return; 
        }
      }
    }

    // Получаем регионы через запрос
    const { data: districts } = accountAPI.useGetDistrictsQuery();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsSubmitting(true);

        try {
            await onSubmit({
                email,
                name,
                message,
                isAccept,
                position,
                region_name,
                topic,
                file,
            });
            console.log('Сообщение успешно отправлено!');
        } catch (error) {
            console.error('Ошибка при отправке:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    // const FileUploadComponent = () => {
    //     const [file, setFile] = useState<File | null>(null)
      
    //     const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //       const selectedFile = e.target.files?.[0] || null
    //       setFile(selectedFile)
    //     }
    // }

    return (
      <div className="login-page__content">
        <div className="login-page__top login-page__top_support">
          <h1 className="login-page__title">
            Обращение в техническую поддержку
          </h1>
          <p className="login-page__text">
            Заполните поля, чтобы отправить обращение в техподдержку.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="reg-form">
            {/* Поле для Email */}
            <div className="reg-form__cols">
              <div className="reg-form__col">
                <div className="reg-form__input-wrap reg-form__input-wrap_support input-wrap input-wrap_small-mob">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="text-input"
                    placeholder="Ваш Email"
                    required
                  />
                </div>
              </div>
            </div>

            {/* Поле для Contact Details */}
            <div className="reg-form__cols">
              <div className="reg-form__col">
                <div className="reg-form__input-wrap reg-form__input-wrap_support input-wrap input-wrap_small-mob">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="text-input"
                    placeholder="Ваше Ф.И.О."
                    required
                  />
                </div>
              </div>
            </div>

            {/* Поле для выбора региона через SelectComponent */}
            <div className="profile-form__col reg-form__cols">
              <div className="profile-form__input-wrap input-wrap">
                <label className="top-label">Регион</label>
                <div className="select">
                  <SelectComponent
                    options={districts || []}
                    onChange={(newValue: string | null) => {
                      return setRegionName(newValue ?? "");
                    }}
                    currentValue={region_name}
                    placeholder="Выберите регион"
                    canBeNull={true}
                  />
                </div>
              </div>
            </div>

            {/* Поле для должности */}
            <div className="profile-form__col reg-form__cols">
              <div className="profile-form__input-wrap input-wrap">
                <input
                  type="text"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  className="text-input"
                  placeholder="Должность"
                  required
                />
              </div>
            </div>

            {/* Поле для темы обращения */}
            <div className="profile-form__col reg-form__cols">
              <div className="profile-form__input-wrap input-wrap">
                <input
                  type="text"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  className="text-input"
                  placeholder="Тема обращения"
                  required
                />
                <div className="character-count">
                  {topic.length}/{maxLenghtTopic} символов
                </div>
              </div>
            </div>

            {/* Поле для сообщения */}
            <div className="reg-form__cols">
              <div className="reg-form__col">
                <div className="reg-form__input-wrap reg-form__input-wrap_support input-wrap input-wrap_small-mob">
                  <textarea
                    value={message}
                    onChange={handleMessageChange}
                    maxLength={maxLengthMessage}
                    className="textarea"
                    placeholder="Ваше обращение"
                    required
                  />
                  <div className="character-count">
                    {message.length}/{maxLengthMessage} символов
                  </div>
                </div>
              </div>
            </div>

            {/* Поле для загрузки файла */}
            <div className="reg-form__cols">
              <div className="reg-form__col">
                <div className="reg-form__input-wrap">
                  <label htmlFor="file-upload" className="file-upload-label">
                    {/* <img
                      src={fileLogo}
                      alt="Иконка загрузки"
                      className="file-icon"
                    /> */}
                  </label>
                  <input
                    type="file"
                    id="file-upload"
                    onChange={handleFileChange}
                    className="file-input"
                  />
                </div>
              </div>
            </div>

            {/* Чекбокс согласия */}
            <div className="reg-form__checkbox checkbox">
              <label className="checkbox__label">
                <input
                  type="checkbox"
                  checked={isAccept}
                  onChange={(e) => setIsAccept(e.target.checked)}
                  className="checkbox__input"
                  required
                />
                <span className="checkbox__icon"></span>
                <span className="checkbox__text">
                  Согласен на обработку персональных данных
                </span>
              </label>
            </div>

            {/* Кнопка отправки */}
            <div className="reg-form__cols">
              <div className="reg-form__col">
                <button
                  className="reg-form__button button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Отправка..." : "Отправить"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
};
