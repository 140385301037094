import {baseAPI} from "./base";
import {IRegistrationBody, IUser, IUsersFilters} from "../types/users";
import {IPage} from "../types/page";
import {IPagination} from "../types/pagination";
import {saveAs} from "file-saver";


const baseURL = "/users"
const userAPI = baseAPI.enhanceEndpoints({addTagTypes: ["User"]})

export const usersAPI = userAPI.injectEndpoints({
    endpoints: (builder) => ({
            // это регистрация нового пользователя
            registration: builder.mutation<void, Partial<IRegistrationBody>>({
                    query: (body) => ({
                        url: `${baseURL}`,
                        method: "POST",
                        body: body
                    }),

                }
            ),
            // изменение данных о пользователе
            // updateUser: builder.mutation<void, Partial<IUser>>({
            //         query: (body) => ({
            //             url: `${baseURL}/${[body.id]}`,
            //             method: "POST",
            //             body: body
            //         }),
 
            //     },
            // ),

            updateUser: builder.mutation<void, Partial<IUser>>({
                query: (user) => ({
                    url: `${baseURL}/${user.id}`,
                    method: "POST",
                    body: user
                }),

            },
        ),
            // получение всех пользователей
            getUsers: builder.query<IPage<IUser>, IPagination & IUsersFilters>({
                query: (params) => {
                    return ({
                        params: params,
                        method: "GET",
                        url: `${baseURL}`
                    })
                },
            }),
            // получение пользователя
            getUser: builder.query<IUser, number>({
                query: (id: number) => ({
                    url: `${baseURL}/${id}`,
                    method: "GET"
                }),
                providesTags: ["User"]
            }),
            getUsersCsv: builder.query<File, IUsersFilters>({
                query: (params) => ({
                    method: "GET",
                    params: params,
                    url: `${baseURL}/export`,
                    cache: "no-cache",
                    responseHandler: async (response) => {
                        const file = new File([await response.blob()], "users.csv")
                        saveAs(window.URL.createObjectURL(file), "users.csv")
                    }
                }),
                providesTags: ["User"],
    
            }),
            deleteUser: builder.mutation<number, number>({
                query: (user_id) => ({
                    method: "POST",
                    body: {
                        user_id
                    },
                    url: `${baseURL}/delete-user`
                }),
                invalidatesTags: ["User"]
            }),
            deleteUsers: builder.mutation<void, Array<number>>({
                query: (ids) => ({
                    url: `${baseURL}/delete-users`,
                    method: "DELETE",
                    body: ids
                }),
                invalidatesTags: ["User"]
            }),
        }
    )
})