import { IYouTubeVideoFilters } from './../../../../types/youtube.d';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPagination } from '../../../../types/pagination';

const initialState = {
    pagination: {
        page: 1,
        perPage: 5
    } as IPagination,
    filters: {
        sort_field: "last_post_date",
        sort_direction: "desc"
    } as IYouTubeVideoFilters
}

export const youtubeChannelDetailSlice = createSlice({
    name: "youtubeChannelDetail",
    initialState,
    reducers: {
        setPaginationPage(state, action: PayloadAction<{ page: number }>) {
            state.pagination.page = action.payload.page
        },
        setPaginationPerPage(state, action: PayloadAction<{ perPage: number }>) {
            state.pagination.perPage = action.payload.perPage
        },
    },

})

export const { setPaginationPage, setPaginationPerPage } = youtubeChannelDetailSlice.actions