import { accountAPI } from './../api/account';
import { useTypedDispatch } from './common';
import { useNavigate } from 'react-router-dom';
import { setJwt } from '../store/account/account';
import { useSelector } from 'react-redux';
import { accountSelectors } from '../store/account/selectors';
import { Cookie } from '../utils/cookie';
import { loginPath } from '../constants/links';

export const useLogout = () => {
    const cookie = new Cookie("access_token")
    const navigate = useNavigate()
    const dispatch = useTypedDispatch()

    return () => {
        cookie.setValue("", 0)
        navigate(loginPath)
        dispatch(setJwt({ access_token: "" }))
    }
}

export const useGetCurrentUser = () => {
    const access_token = useSelector(accountSelectors.access_token)

    const data = accountAPI.useGetCurrentQuery({ access_token })

    if (data.isError) {
        new Cookie("access_token").setValue("", 0)
    }

    return data
}

