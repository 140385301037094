import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { vkUsersPath } from '../../../constants/links'
import { locationPipes } from '../../../pipes/location/location'
import { IVkUser } from '../../../types/vk'
import { formatDate } from '../../../utils/date'
import { MoreBtn } from '../../Common/UI/Table/MoreBtn'
import {vkStorage, UserRightsEnum} from "../../../constants/api";

interface IProps {
    user: IVkUser
    isSelected: boolean
    toggleSelectedUser: (id: number) => void
    onDelete: (id: number) => void
    onCheck: (id: number) => void
}


export const VkUser: React.FC<IProps> = React.memo(({ isSelected, onCheck, toggleSelectedUser, user, onDelete }) => {
    const navigate = useNavigate()

    const onGetDetailedHandler = useCallback(() => {
        navigate(`${vkUsersPath}/${user.id}`)
    }, [])

    const actions = [{ text: "Подробнее", callback: onGetDetailedHandler }]

    return (
        <div className="table__row">
            <div className="table__cell table__cell_top table__cell_check" data-cell>
                <div className="table__checkbox checkbox">
                    <label className="checkbox__label">
                        <input checked={isSelected} onChange={() => toggleSelectedUser(user.id)} className="checkbox__input" type="checkbox" />
                        <span className="checkbox__icon"></span>
                    </label>
                </div>
                <div className="table-user">
                    <img className="table-user__photo" src={vkStorage + user.image_path} alt="" />
                    <a target='_blank' rel="noopener noreferrer" href={`${user.url}`}><span className="table-user__name">{user.last_name} {user.first_name} {user.middle_name}</span></a>
                </div>
            </div>
            <div className="table__cell table__cell_mob" data-cell>
                <div className="table__mob-cols">
                    <span className="table__mob-desc">Пол</span>
                    <span className="table__mob-text">{user.sex === 'M' ? "муж" : "жен"}</span>
                </div>
            </div>
            <div className="table__cell table__cell_mob" data-cell>
                <div className="table__mob-cols">
                    <span className="table__mob-desc">Дата рождения (Возраст)</span>
                    <span className="table__mob-text">{user.birthday === null ? "Неизвестна" : formatDate(user.birthday) }</span>
                </div>
            </div>
            <div className="table__cell table__cell_mob" data-cell>
                <div className="table__mob-cols">
                    <span className="table__mob-desc">Геоданные</span>
                    <span className="table__mob-text">{locationPipes.location([user.country, user.region, user.city])}</span>
                </div>
            </div>
            <div className="table__cell table__cell_mob" data-cell>
                <div className="table__mob-cols">
                    <span className="table__mob-desc">Дата последнего <br />входа в сеть</span>
                    <span className="table__mob-text">
                    {user.last_seen === 0  ? 'Неизвестно' : formatDate(user.last_seen * 1000)}
                    </span>
                </div>
            </div>
            <div className="table__cell table__cell_mob" data-cell>
                <div className="table__mob-cols">
                    <span className="table__mob-desc">Статус</span>
                    <span className="table__mob-text">{user.closed ? "закрытый" : "открытый"}</span>
                </div>
            </div>
            {/* <div className="table__cell table__cell_tablet">
                <div className="table__tablet-cols">
                    <span className="table__tablet-desc">Деструктивные <br />сообщества</span>
                    <span className="table__tablet-text">
                        {<span className="table__category">{user.groups.map((group) => group.id)}</span>}
                    </span>
                </div>
            </div> */}
            <div className="table__cell table__cell_tablet">
                <div className="table__tablet-cols">
                    <span className="table__tablet-desc">Субкультуры</span>
                    <span className="table__tablet-text">
                       {user.subcultures}
                    </span>
                </div>
            </div>
            <div className="table__cell table__cell_top" data-cell>
                <div className="desktop-actions desktop-actions_admin">
                    <div className="desktop-actions__col" onClick={onGetDetailedHandler}>
                        <div className="show-more js-show-more">
                            <a className="show-more__button js-show-button" href="#"></a>
                            <span className="show-more__tooltip js-show-tooltip">Подробнее</span>
                        </div>
                    </div>
                </div>
                <MoreBtn actions={actions} />
            </div>
        </div>
    )
})