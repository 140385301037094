import React, { ReactElement, useState } from 'react'
import classes from '../../../../../src/assets/css/tooltip.module.css'

type PropsType = {
    children: ReactElement;
    text: string;
}

const  Tooltip:React.FC<PropsType> = ({children, text}) => {

    const [showTooltip, setShowTooltip] = useState(false)

    const onMouseEnterHandler = () => {
        setShowTooltip(true)
    }

    const onMouseLeaveHandler = () => {
        setShowTooltip(false)
    }

  return (
    <div className={classes.container} onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
        {children}
        {showTooltip && <div className={classes.tooltip}>{text}</div>}
    </div>
  )
}

export default Tooltip