import {skipToken} from '@reduxjs/toolkit/dist/query'
import React, {useState, useMemo} from 'react'
import {subculturesAndTagsAPI} from '../../../api/subculturesAndTags'
import {FiltersType, IPermissibleSortBy, SortDirection} from '../../../types/filters'
// import {IYouTubeChannelsFilters, IYouTubeVideoFilters, SortBy} from '../../../types/youtube'
import {FilterDate} from '../../Common/Filters/FilterDate'
import {FilterRange} from '../../Common/Filters/FilterRange'
import {FilterRangeSpan} from '../../Common/Filters/FilterRangeSpan'
import {FilterSearch} from '../../Common/Filters/FilterSearch'
import {FiltersNavBtn} from '../../Common/Filters/FiltersNavBtn'
import {FilterSort} from '../../Common/Filters/FilterSort'
import {BuildMultiselect} from '../../Common/UI/Multiselect'
import {convertDate} from "../../../utils/date";
// import {youtubeAPI} from "../../../api/youtube";
import { ITwitchPostsFilters, ITwitchStreamsFilters, SortBy } from '../../../types/twitch'

interface IProps {
    filters: Partial<ITwitchStreamsFilters> & Partial<ITwitchPostsFilters>
    onChangeKeyword: (keyword: string) => void
    onChangeSortBy: (sort_field: SortBy) => void
    onChangeSortDirection: (sort_direction: SortDirection) => void
    onChangeCountries: (countries: string | null) => void
    onChangeSubscribersCountMin?: (min_followers: number) => void
    onChangeSubscribersCountMax?: (max_followers: number) => void
    onChangePostsCountMin?: (min_videos: number) => void
    onChangePostsCountMax?: (max_videos: number) => void
    onChangeCommentsCountMin?: (min_comments: number) => void
    onChangeCommentsCountMax?: (max_comments: number) => void
    onChangeViewsCountMin?: (min_views: number) => void
    onChangeViewsCountMax?: (max_views: number) => void
    onChangeSubcultures: (subcultures: string | null) => void
    onChangeTags: (tags: string | null) => void
    onChangeDateAt: (date: Date | null) => void
    onChangeDateTo: (date: Date | null) => void
    clearFilters: () => void
    applyFilters: () => void
    onDownloadCsv: () => void
    permissibleSortBy: Array<IPermissibleSortBy>
}

export const TwitchFilters: React.FC<IProps> = React.memo((props) => {
    const {
        filters, onChangePostsCountMax, onChangePostsCountMin,
        onChangeSubscribersCountMax, onChangeSubscribersCountMin, onChangeKeyword,
        onChangeSortBy, onChangeSortDirection, permissibleSortBy,
        onChangeSubcultures, onChangeTags, applyFilters, clearFilters,
        onDownloadCsv,
        onChangeDateAt, onChangeDateTo, onChangeCommentsCountMax, onChangeCommentsCountMin, onChangeViewsCountMax,
        onChangeViewsCountMin, onChangeCountries
    } = props
    const [activeFilters, setActiveFilters] = useState<Array<FiltersType>>([])

    const {data: subcultures} = subculturesAndTagsAPI.useGetAllSubculturesQuery()
    const {data: tags} = subculturesAndTagsAPI.useGetTagsBySubcultureQuery((filters?.subcultures && filters.subcultures) ?? skipToken)

    // const { data: countries } = youtubeAPI.useGetCountriesQuery();

    const subcultures_div = BuildMultiselect(subcultures, filters.subcultures, onChangeSubcultures, 'Субкультуры', 'subculture_name')

    const tags_div = BuildMultiselect(tags, filters.tags, onChangeTags, 'Теги', 'tag_name')

    // const countries_div = BuildMultiselect(countries, filters.countries, onChangeCountries, 'Страны', 'country')

    const updateActiveFilters = (filter: FiltersType) => {
        if (!activeFilters.includes(filter)) {
            setActiveFilters([...activeFilters, filter])
        }
    }

    return (
        <div className="filters js-filter">

            <div className="filters__cols-wrap">
                <div className="filters__cols">
                    {typeof filters.keyword === "string" && <div className="filters__col filters__col_search">
                        <div className="search-filter search-filter_hide-mob">
                            <FilterSearch keyword={filters.keyword} onChangeKeyword={onChangeKeyword}
                                          onSubmit={applyFilters}/>
                        </div>
                        <FiltersNavBtn isActive={activeFilters.includes("search")}
                                       onClick={() => updateActiveFilters("search")}
                                       className="filter-icon_1 filters__mob-button">

                        </FiltersNavBtn>
                    </div>}
                    <div className="filters__col filters__col_main">
                        <div className="filters__inner-cols">
                            <div className="filters__inner-col">
                                <FiltersNavBtn isActive={activeFilters.includes("date")}
                                               onClick={() => updateActiveFilters("date")} className="filter-icon_2">
                                    {filters.dateAt !== undefined && filters.dateTo !== undefined &&
                                        <FilterRangeSpan className="filter-icon__text"
                                                         dateAt={convertDate(filters.dateAt)}
                                                         dateTo={convertDate(filters.dateTo)}/>}
                                </FiltersNavBtn>
                            </div>
                            <div className="filters__inner-col">
                                <FiltersNavBtn isActive={activeFilters.includes("main")}
                                               onClick={() => updateActiveFilters("main")} className="filter-icon_3">
                                    <span className="filter-icon__text">Фильтры</span>
                                </FiltersNavBtn>
                            </div>
                            <div className="filters__inner-col">
                                <FiltersNavBtn isActive={activeFilters.includes("sort")}
                                               onClick={() => updateActiveFilters("sort")} className="filter-icon_4">
                                    <span className="filter-icon__text">Сортировка</span>
                                </FiltersNavBtn>
                            </div>
                        </div>
                    </div>
                    <div className="filters__col filters__col_export">
                        {/* <button className="export-button" type="button"> тут должно быть так */}
                        <button  onClick={onDownloadCsv} className="export-button" type="button">
                            <span className="export-button__text">Экспорт</span>
                        </button>
                    </div>
                </div>
            </div>

            {!!activeFilters.length && <div className="js-filter-container">
                <div className="filters__bg">
                    {
                        activeFilters.includes("search") && typeof filters.keyword === "string" &&
                        <div className="filter js-filter-content" data-id="search">
                            <div className="search-filter search-filter__mob">
                                <FilterSearch keyword={filters.keyword} onChangeKeyword={onChangeKeyword}
                                              onSubmit={applyFilters}/>
                            </div>
                        </div>
                    }
                    {
                        activeFilters.includes("date") && filters.dateAt !== undefined && filters.dateTo !== undefined &&
                        <FilterDate dateAt={convertDate(filters.dateAt)} dateTo={convertDate(filters.dateTo)}
                                    onChangeDateAt={onChangeDateAt} onChangeDateTo={onChangeDateTo}/>
                    }
                    {
                        activeFilters.includes("main") && <div className="filter js-filter-content" data-id="filters">
                            <div className="main-filters">
                                <div className="main-filters__cols main-filters__cols_youtube">
                                    {onChangeSubscribersCountMax && onChangeSubscribersCountMin && typeof filters.max_followers === "number" &&
                                        typeof filters.min_followers === "number" &&

                                        <div className="main-filters__col main-filters__col_tablet-last">
                                            <FilterRange onUpdateEndValue={onChangeSubscribersCountMax}
                                                         onUpdateStartValue={onChangeSubscribersCountMin} min={0}
                                                         max={900000} startValue={filters.min_followers}
                                                         endValue={filters.max_followers} text="Количество подписчиков"/>
                                        </div>
                                    }

                                    {onChangePostsCountMax && onChangePostsCountMin && typeof filters.max_videos === "number" &&
                                        typeof filters.min_videos === "number" &&
                                        <div className="main-filters__col main-filters__col_tablet-last">
                                            <FilterRange onUpdateEndValue={onChangePostsCountMax}
                                                         onUpdateStartValue={onChangePostsCountMin} min={0} max={900000}
                                                         startValue={filters.min_videos} endValue={filters.max_videos}
                                                         text="Количество видео"/>
                                        </div>
                                    }

                                    {/* {onChangeViewsCountMax && onChangeViewsCountMin && typeof filters.max_views === "number" &&
                                        typeof filters.min_views === "number" &&
                                        <div className="main-filters__col main-filters__col_tablet-last">
                                            <FilterRange onUpdateEndValue={onChangeViewsCountMax}
                                                         onUpdateStartValue={onChangeViewsCountMin} min={0}
                                                         max={10000000} startValue={filters.min_views}
                                                         endValue={filters.max_views} text="Количество реакций"/>
                                        </div>
                                    } */}


                                    {onChangeCommentsCountMax && onChangeCommentsCountMin && typeof filters.max_comments === "number" &&
                                        typeof filters.min_comments === "number" &&
                                        <div className="main-filters__col main-filters__col_tablet-last">
                                            <FilterRange onUpdateEndValue={onChangeCommentsCountMax}
                                                         onUpdateStartValue={onChangeCommentsCountMin} min={0}
                                                         max={10000000} startValue={filters.min_comments}
                                                         endValue={filters.max_comments}
                                                         text="Количество комментариев"/>
                                        </div>
                                    }

                                    {subcultures_div}
                                    {filters.subcultures && tags_div}

                                    {/* {countries_div} */}

                                </div>

                            </div>
                        </div>
                    }
                    {
                        activeFilters.includes("sort") && filters.sort_field && filters.sort_direction &&
                        <FilterSort
                            permissibleSortBy={permissibleSortBy}
                            onChangeSortBy={e => onChangeSortBy(e as SortBy)}
                            onChangeSortDirection={onChangeSortDirection}
                            sort_field={filters.sort_field}
                            sort_direction={filters.sort_direction}
                        />
                    }


                    <div className="main-filters__bottom">
                        <button onClick={applyFilters} className="main-filters__button button button_small"
                                type="button">Применить фильтры
                        </button>
                        <button onClick={clearFilters} className="main-filters__button button button_small button_grey"
                                type="button">Очистить
                        </button>
                    </div>
                    <button onClick={() => setActiveFilters([])}
                            className="filters__close-button close-filter js-filter-close" type="button"></button>

                    <button onClick={() => setActiveFilters([])}
                            className="filters__mob-button slide-button js-filter-close" type="button"></button>
                </div>
            </div>
            }

        </div>)
})
