import { cloneDeep } from 'lodash';
import { subtractDate } from './../../../utils/date';
import { SortDirection } from './../../../types/filters.d';
import { IYouTubeVideoFilters, SortBy } from './../../../types/youtube.d';
import { IPagination } from '../../../types/pagination';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const baseFilters = {
    tags: null as string | null,
    subcultures: null as string | null,
    keyword: "",
    sort_field: "video_create_time" as SortBy,
    sort_direction: "desc" as SortDirection,
    dateAt: null,
    dateTo: null,
    isChecked: false,
    min_views: 0,
    max_views: 10000000,
    min_comments: 0,
    max_comments: 10000000
} as IYouTubeVideoFilters

const initialState = {
    filters: cloneDeep(baseFilters),
    currentFilters: cloneDeep(baseFilters),
    pagination: {
        page: 1,
        perPage: 20
    } as IPagination,
    selectedVideos: [] as Array<string>
}

export const youtubeVideosSlice = createSlice({
    name: "youtubeVideos",
    initialState,
    reducers: {
        setFiltersTag(state, action: PayloadAction<{ tags: string | null }>) {
            state.filters.tags = action.payload.tags
        },
        setFiltersSubcultures(state, action: PayloadAction<{ subcultures: string | null }>) {
            state.filters.subcultures = action.payload.subcultures
        },
        setFiltersKeyword(state, action: PayloadAction<{ keyword: string }>) {
            state.filters.keyword = action.payload.keyword
        },
        setFiltersSortBy(state, action: PayloadAction<{ sort_field: SortBy }>) {
            state.filters.sort_field = action.payload.sort_field
        },
        setFiltersSortDirection(state, action: PayloadAction<{ sort_direction: SortDirection }>) {
            state.filters.sort_direction = action.payload.sort_direction
        },
        setFiltersDateAt(state, action: PayloadAction<{ dateAt: Date | null }>) {
            state.filters.dateAt = action.payload.dateAt?.getTime() ?? null
        },
        setFiltersIsChecked(state, action: PayloadAction<{ isChecked: boolean }>) {
            state.currentFilters.isChecked = action.payload.isChecked
            state.filters.isChecked = action.payload.isChecked
        },
        setFiltersCommentsCountMin(state, action: PayloadAction<{ min_comments: number }>) {
            state.filters.min_comments = action.payload.min_comments
        },
        setFiltersCommentsCountMax(state, action: PayloadAction<{ max_comments: number }>) {
            state.filters.max_comments = action.payload.max_comments
        },
        setFiltersViewsCountMin(state, action: PayloadAction<{ min_views: number }>) {
            state.filters.min_views = action.payload.min_views
        },
        setFiltersViewsCountMax(state, action: PayloadAction<{ max_views: number }>) {
            state.filters.max_views = action.payload.max_views
        },
        setFiltersDateTo(state, action: PayloadAction<{ dateTo: Date | null }>) {
            state.filters.dateTo = action.payload.dateTo?.getTime() ?? null
        },
        clearFilters(state) {
            state.filters = cloneDeep(baseFilters)
        },
        setCurrentFilters(state, action: PayloadAction<{ currentFilters: IYouTubeVideoFilters }>) {
            state.currentFilters = action.payload.currentFilters
        },
        setPaginationPage(state, action: PayloadAction<{ page: number }>) {
            state.pagination.page = action.payload.page
        },
        setPaginationPerPage(state, action: PayloadAction<{ perPage: number }>) {
            state.pagination.perPage = action.payload.perPage
        },
        toggleSelectedPost(state, action: PayloadAction<{ id: string, isSelected?: boolean }>) {
            const id = action.payload.id
            const isSelected = action.payload.isSelected

            if (isSelected !== true && state.selectedVideos.includes(id)) {
                state.selectedVideos.splice(state.selectedVideos.indexOf(id), 1)
            } else if (!state.selectedVideos.includes(id)) {
                state.selectedVideos.push(id)
            }
        },
        clearSelectedPosts(state) {
            state.selectedVideos = []
        }
    }
})

export const {
    setFiltersTag, setCurrentFilters, setFiltersSubcultures, setPaginationPage, setPaginationPerPage, clearFilters,
    setFiltersKeyword, setFiltersSortBy, setFiltersSortDirection, setFiltersDateAt, setFiltersDateTo, toggleSelectedPost,
    setFiltersCommentsCountMax, setFiltersCommentsCountMin, setFiltersViewsCountMax, setFiltersViewsCountMin,
    clearSelectedPosts, setFiltersIsChecked } = youtubeVideosSlice.actions 