import { SortDirection } from './../../../types/filters.d';
import { ITelegramChannelsFilters, SortBy } from './../../../types/telegram.d';
import { IPagination } from './../../../types/pagination.d';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { ITwitchChannelsFilters } from '../../../types/twitch';

const baseFilters = {
    tags: null as string | null,
    subcultures: null as string | null,
    keyword: "",
    sort_field: "title" as SortBy, // не фильтрованные
    sort_direction: "asc" as SortDirection,
    min_subscribers: 0,
    max_subscribers: 10000000,
    min_posts: 0,
    max_posts: 15000,
    dateAt: null,
    dateTo: null,
    isChecked: false
} as ITwitchChannelsFilters

const initialState = {
    filters: cloneDeep(baseFilters),
    currentFilters: cloneDeep(baseFilters),
     pagination: {
        page: 1,
        perPage: 20
    } as IPagination,
    selectedChannels: [] as Array<number>
}

export const twitchChannelsSlice = createSlice({
    name: "twitchChannels",
    initialState,
    reducers: {
        setFiltersTags(state, action: PayloadAction<{ tags: string | null }>) {
            state.filters.tags = action.payload.tags
        },
        setFiltersSubcultures(state, action: PayloadAction<{ subcultures: string | null }>) {
            state.filters.subcultures = action.payload.subcultures
        },
        setFiltersSortBy(state, action: PayloadAction<{ sort_field: SortBy }>) {
            state.filters.sort_field = action.payload.sort_field
        },
        setFiltersSortDirection(state, action: PayloadAction<{ sort_direction: SortDirection }>) {
            state.filters.sort_direction = action.payload.sort_direction
        },
        setFiltersKeyword(state, action: PayloadAction<{ keyword: string }>) {
            state.filters.keyword = action.payload.keyword
        },
        setFiltersIsChecked(state, action: PayloadAction<{ isChecked: boolean }>) {
            state.currentFilters.isChecked = action.payload.isChecked
            state.filters.isChecked = action.payload.isChecked
        },
        setFiltersSubscribersCountMin(state, action: PayloadAction<{ min_subscribers: number }>) {
            state.filters.min_subscribers = action.payload.min_subscribers
        },
        setFiltersSubscribersCountMax(state, action: PayloadAction<{ max_subscribers: number }>) {
            state.filters.max_subscribers = action.payload.max_subscribers
        },
        setFiltersPostsCountMin(state, action: PayloadAction<{ min_posts: number }>) {
            state.filters.min_posts = action.payload.min_posts
        },
        setFiltersPostsCountMax(state, action: PayloadAction<{ max_posts: number }>) {
            state.filters.max_posts = action.payload.max_posts
        },
        setFiltersDateAt(state, action: PayloadAction<{ dateAt: Date | null }>) {
            state.filters.dateAt = action.payload.dateAt?.getTime() ?? null
        },
        setFiltersDateTo(state, action: PayloadAction<{ dateTo: Date | null }>) {
            state.filters.dateTo = action.payload.dateTo?.getTime() ?? null
        },
        setCurrentFilters(state, action: PayloadAction<{ currentFilters: ITelegramChannelsFilters }>) {
            state.currentFilters = action.payload.currentFilters
        },
        clearFilters(state) {
            state.filters = cloneDeep(baseFilters)
        },
        setPaginationPage(state, action: PayloadAction<{ page: number }>) {
            state.pagination.page = action.payload.page
        },
        setPaginationPerPage(state, action: PayloadAction<{ perPage: number }>) {
            state.pagination.perPage = action.payload.perPage
        },
        setRankFilter(state, action: PayloadAction<{ ranks: string | null }>) {
            state.filters.ranks = action.payload.ranks
        },
        toggleSelectedChannel(state, action: PayloadAction<{ id: number, isSelected?: boolean }>) {
            const id = action.payload.id
            const isSelected = action.payload.isSelected

            if (isSelected !== true && state.selectedChannels.includes(id)) {
                state.selectedChannels.splice(state.selectedChannels.indexOf(id), 1)
            } else if (!state.selectedChannels.includes(id)) {
                state.selectedChannels.push(id)
            }
        },
        clearSelectedChannels(state) {
            state.selectedChannels = []
        }
    }
})

export const {
    setFiltersTags, setCurrentFilters, setFiltersSubcultures, setFiltersPostsCountMax, setFiltersPostsCountMin,
    setFiltersSubscribersCountMax, setFiltersSubscribersCountMin, setPaginationPage, setPaginationPerPage, setFiltersKeyword,
    setFiltersSortDirection, setFiltersSortBy, clearFilters, setFiltersDateAt, setFiltersDateTo, clearSelectedChannels,
    toggleSelectedChannel, setFiltersIsChecked, setRankFilter } = twitchChannelsSlice.actions 
