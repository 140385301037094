import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IVkInfographicsUsersFilters } from '../../../../types/vk';

const baseFilters = {
    min_subscribers: 0,
    max_subscribers: 10000000,
    country: null,
    region: null,
    city: null,
    university: null,
    actual: false,
    ageStart: null,
    ageEnd: null
} as IVkInfographicsUsersFilters

const initialState = {
    filters: cloneDeep(baseFilters),
    currentFilters: cloneDeep(baseFilters)
}

export const vkUsersInfographicsSlice = createSlice({
    name: "vkUsersInfographicsSlice",
    reducers: {
        setFiltersCountry(state, action: PayloadAction<{ country: string | null }>) {
            state.filters.country = action.payload.country
        },
        setFiltersDistrict(state, action: PayloadAction<{ region: string | null }>) {
            state.filters.region = action.payload.region
        },
        setFiltersCity(state, action: PayloadAction<{ city: string | null }>) {
            state.filters.city = action.payload.city
        },
        setFiltersUniversity(state, action: PayloadAction<{ university: string | null }>) {
            state.filters.university = action.payload.university
        },
        setFiltersAgeStart(state, action: PayloadAction<{ ageStart: number | null }>) {
            state.filters.ageStart = action.payload.ageStart
        },
        setFiltersAgeEnd(state, action: PayloadAction<{ ageEnd: number | null }>) {
            state.filters.ageEnd = action.payload.ageEnd
        },
        setFiltersSubscribersCountMin(state, action: PayloadAction<{ min_subscribers: number }>) {
            state.filters.min_subscribers = action.payload.min_subscribers
        },
        setFiltersSubscribersCountMax(state, action: PayloadAction<{ max_subscribers: number }>) {
            state.filters.max_subscribers = action.payload.max_subscribers
        },
        setCurrentFilters(state, action: PayloadAction<{ currentFilters: IVkInfographicsUsersFilters }>) {
            state.currentFilters = { ...action.payload.currentFilters }
        },
        clearFilters(state) {
            state.filters = cloneDeep(baseFilters)
        },
    },
    initialState
})

export const {
    setCurrentFilters, clearFilters, setFiltersCountry, setFiltersCity, setFiltersUniversity,
    setFiltersDistrict, setFiltersSubscribersCountMax, setFiltersSubscribersCountMin,
    setFiltersAgeStart,setFiltersAgeEnd
} = vkUsersInfographicsSlice.actions