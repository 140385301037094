import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { UserRightsEnum, youtubeStorage } from '../../../constants/api'
import { twitchUsersPath, youtubeChannelsPath } from '../../../constants/links'
import { formatDate } from '../../../utils/date'
import { DynamicSpan } from '../../Common/UI/Table/DynamicSpan'
import { MoreBtn } from '../../Common/UI/Table/MoreBtn'
import { useSelector } from 'react-redux'
import { youtubeChannelsSelectors } from '../../../store/youtube/channels/selectors'
import { youtubeAPI } from '../../../api/youtube'
import { IYouTubeChannel } from '../../../types/youtube'
import {useGetCurrentUser} from "../../../hooks/account";
import {is_moderator, is_admin} from "../../../utils/rights";
import Tooltip from '../../Common/UI/Table/Tooltip'


// тут все youtube каналы + данные о них

interface IProps {
    // channel: IYouTubeChannel
    channel: any
    isSelected: boolean
    toggleSelectedChannel: (id: number) => void
    onDelete: (id: number) => void
    onCheck: (id: number) => void
    onBook: (id: number) => void
    onUnBook: (id: number) => void
}



export const TwitchStream: React.FC<IProps> = React.memo(({ 
    isSelected, 
    toggleSelectedChannel, 
    channel, 
    onDelete,
    onCheck, 
    onBook,
    onUnBook
}) => {
    const navigate = useNavigate()

    const { data: current_user } = useGetCurrentUser()

    const onDeleteHandler = useCallback(() => {
        onDelete(channel.channel_id)
    }, [])

    const onCheckHandler = useCallback(() => {
        onCheck(channel.channel_id)
    }, [])

    const onGetDetailedHandler = useCallback(() => {
        navigate(`${twitchUsersPath}/${channel.channel_id}`)
    }, [])

    const onBookHandler = useCallback(() => {
        onBook(channel.channel_id)
    }, [])

    const onUnBookHandler = useCallback(() => {
        onUnBook(channel.channel_id)
    }, []) 

    const actions = [
        { text: "Подробнее", callback: onGetDetailedHandler },
        // { text: "Добавить", callback: onCheckHandler, rights: UserRightsEnum.admin },
        { text: "Удалить", class: "status-nav__link_red", callback: onDeleteHandler, rights: UserRightsEnum.moderator },
        { text: "Забронировать", callback: onBookHandler },
        { text: "Разбронировать", callback: onUnBookHandler }
    ]

    return (
        <div className="table__row">
            <div className="table__cell table__cell_top table__cell_check" data-cell>
                <div className="table-user">
                    {/* <img className="table-user__photo" src={youtubeStorage + channel.image_path} alt="" /> */}
                    <span className="table-user__name"><a href={channel.link} target="_blank" >{channel.stream_name}</a></span>
                </div>
                <div className="table__checkbox checkbox">
                    <label className="checkbox__label">
                        <input checked={isSelected} onChange={() => toggleSelectedChannel(channel.channel_id)} className="checkbox__input" type="checkbox" />
                        <span className="checkbox__icon"></span>
                    </label>
                </div>
            </div>
            {/* <div className="table__cell table__cell_tablet">
                <div className="table__tablet-cols">
                    <span className="table__tablet-desc">Описание</span>
                    <span className="table__tablet-text">
                        {channel.description.length > 100 ? channel.description.substring(0, 30) + "..." : channel.description}
                    </span>
                </div>
            </div> */}
            <div className="table__cell table__cell_stats" data-cell>
                <span className="table__stats-desc">Страна<br />профиля</span>
                <span className="table__stats-text">{channel.language}</span>
            </div>
            <div className="table__cell table__cell_stats" data-cell>
                <span className="table__stats-desc">Дата<br />регистрации</span>
                <span className="table__stats-text">{formatDate(channel.start_time)}</span>
            </div>
            {/* <div className="table__cell table__cell_stats" data-cell>
                <span className="table__stats-desc">Дата последней <br />добавленной публикации</span>
                <span className="table__stats-text">{formatDate(channel.last_post_date)}</span>
            </div>
            <div className="table__cell table__cell_stats" data-cell>
                <span className="table__stats-desc">Подписчики<br />месяц (всего)</span>
                <span className="table__stats-text">{channel.follower_count}</span>
            </div>
            <div className="table__cell table__cell_stats" data-cell>
                <span className="table__stats-desc">Количество<br />видео (всего)</span>
                <span className="table__stats-text">{channel.video_count}</span>
            </div>
            <div className="table__cell table__cell_stats" data-cell>
                <span className="table__stats-desc">Просмотры<br />месяц (всего)</span>
                <span className="table__stats-text">{channel.view_count}</span>
            </div> */}
            <div className="table__cell table__cell_tablet">
                <div className="table__tablet-cols">
                    <span className="table__tablet-desc">Теги</span>
                    <span className="table__tablet-text">
                        <span className="table__tag">{channel.tags}</span>
                    </span>
                </div>
            </div>
            <div className="table__cell table__cell_tablet">
                <div className="table__tablet-cols">
                    <span className="table__tablet-desc">Субкультуры</span>
                    <span className="table__tablet-text">
                        <span className="table__category">{channel.subcultures}</span>
                    </span>
                </div>
            </div>
             {/* <div className="table__cell table__cell_tablet">
                <div className="table__tablet-cols">
                    <span className="table__tablet-desc">Ранг</span>
                    <span className="table__tablet-text"></span>
                </div>
            </div> */}
            <div className="table__cell table__cell_top" data-cell>
                <div className="desktop-actions desktop-actions_admin desktop-actions_visible-mob">
                    <div className="desktop-actions__col" data-cell onClick={() => onGetDetailedHandler()}>
                        <Tooltip text='Подробнее'>
                            <div className="show-more js-show-more">
                                <a className="show-more__button js-show-button" href="#"></a>
                                <span className="show-more__tooltip js-show-tooltip">Подробнее</span>
                            </div>
                        </Tooltip>
                    </div>
                    {/* {
                        false && <div className="desktop-actions__col" onClick={onCheckHandler}>
                            <div className="show-more js-show-more">
                                <button className="action-button action-button_accept" type="button"></button>
                                <span className="show-more__tooltip js-show-tooltip">Добавить</span>
                            </div>
                        </div>
                    } */}
                    { is_moderator(current_user) &&
                    <div className="desktop-actions__col">
                        <Tooltip text='Удалить'>
                            <div className="show-more js-show-more">
                                <button onClick={() => onDelete(channel.channel_id)} className="action-button action-button_remove js-show-button" type="button"></button>
                                <span className="show-more__tooltip js-show-tooltip">Удалить</span>
                            </div>
                        </Tooltip>
                    </div>
                    }
                    
                    { channel.responsible_id === null &&
                        <div className="desktop-actions__col" onClick={() => onBookHandler()}>
                            <Tooltip text='Забронировать'>
                                <div className="show-more js-show-more">
                                    <a className="action-button action-button_book js-show-button" href="#"></a>
                                    <span className="show-more__tooltip js-show-tooltip">Забронировать</span>
                                </div>
                            </Tooltip>
                    </div>
                    }

                    {((channel.responsible_id === current_user?.id || (channel.responsible_id !== null && is_admin(current_user))) && !channel.block_request) &&
                        <div className="desktop-actions__col" onClick={() => onUnBookHandler()}>
                            <Tooltip text='Разбронировать'>
                                <div className="show-more js-show-more">
                                    <a className="action-button action-button_unbook js-show-button" href="#"></a>
                                    <span className="show-more__tooltip js-show-tooltip">Разбронировать</span>
                                </div>
                            </Tooltip>
                    </div>
                    }

                    {((channel.responsible_id !== current_user?.id && channel.responsible_id !== null && !is_admin(current_user)) && !channel.block_request) &&
                    <div className="desktop-actions__col">
                        <Tooltip text='Забронировано'>
                            <div className="show-more js-show-more">
                                <a className="action-button action-button_booked js-show-button" href="#"></a>
                                <span className="show-more__tooltip js-show-tooltip">Забронировано</span>
                            </div>
                        </Tooltip>
                    </div>
                    }

                    {(channel.responsible_id === current_user?.id && channel.block_request) &&
                    <div className="desktop-actions__col">
                        <Tooltip text='Блокировка запрошена'>
                            <div className="show-more js-show-more">
                                <a className="action-button action-button_request_blocked_finish_mine js-show-button" href="#"></a>
                                <span className="show-more__tooltip js-show-tooltip">Блокировка запрошена</span>
                            </div>
                        </Tooltip>
                    </div>
                    }

                    {(channel.responsible_id !== current_user?.id && channel.responsible_id !== null && channel.block_request) &&
                    <div className="desktop-actions__col">
                        <Tooltip text='Блокировка запрошена'>
                            <div className="show-more js-show-more">
                                <a className="action-button action-button_request_blocked_finish_not_mine js-show-button" href="#"></a>
                                <span className="show-more__tooltip js-show-tooltip">Блокировка запрошена</span>
                            </div>
                        </Tooltip>
                    </div>
                    }
                </div>

                <MoreBtn actions={actions} />

            </div>
        </div>
    )
})
